import { Button, Card, theme } from "antd";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import StackedBarChart from "../../../components/rocket/StackedBarChart";
import { InitialValues, KeyValuePair } from "../../../components/rocket/types";
import SkeletonChart from "../../../components/ui/SkeletonChart";
import useNamedRef from "../../../hooks/useNamedRef";
import RocketApi from "../../../utils/api/rocket-api";
import download from "../../../utils/download";

const { useToken } = theme;

const ByDay = () => {
    const { token } = useToken();
    const { t } = useTranslation();
    const formik = useFormikContext<InitialValues>();

    const refByDay = useNamedRef("byDay");
    const refDownloadByDay = useNamedRef("downloadByDay");

    const [loadingByDay, setLoadingByDay] = useState(false);
    const [byDay, setByDay] = useState([]);
    const [localFiltersbyDay, setLocalFiltersbyDay] = useState<KeyValuePair[]>(
        [],
    );

    useEffect(() => {
        let filters: KeyValuePair[] = [];
        for (const key in formik.values.filters) {
            // @ts-ignore
            if (Array.isArray(formik.values.filters[key])) {
                // @ts-ignore
                for (const item of formik.values.filters[key]) {
                    filters.push({ key, value: item });
                }
            } else {
                // @ts-ignore
                filters.push({ key, value: formik.values.filters[key] });
            }
        }
        if (formik.values.settings.provider === "aws") {
            const params = new URLSearchParams(window.location.search);
            for (const [key, value] of params) {
                if (key === "margin") {
                    filters.push({ key, value });
                }
            }
        }
        setLocalFiltersbyDay(filters);
    }, [formik.values.filters, formik.values.settings.provider]);

    useEffect(() => {
        const controller = new AbortController();
        if (localFiltersbyDay.length > 0) {
            const fetchData = async (controller: AbortController) => {
                try {
                    setLoadingByDay(true);
                    const api = new RocketApi();
                    // get invoice type list
                    const response = await api.request(
                        `${formik.values.settings.provider}/by-day`,
                        {
                            body: JSON.stringify({
                                filters: localFiltersbyDay,
                            }),
                            controller,
                        },
                    );

                    let data = await response?.json();
                    if (response && response.status === 200) {
                        data = data.map((d: any) => {
                            const invoiceTypesStorage: string =
                                localStorage.getItem(
                                    `invoice_type_${formik.values.settings.provider}`,
                                ) || "";

                            // check if it's saved in storage and get the correspondent invoice.
                            if (invoiceTypesStorage !== "") {
                                const invoiceTypes =
                                    JSON.parse(invoiceTypesStorage);
                                if (invoiceTypes.length > 0) {
                                    const invoice =
                                        invoiceTypes.find(
                                            (type: any) => type.value == d.type,
                                        ) || {};

                                    d.type = invoice.label || "";
                                }
                            }

                            if (d.type === "" || d.type === "regular") {
                                d.type = "General";
                            }

                            return d;
                        });

                        setByDay(data);
                        setLoadingByDay(false);
                    }
                } catch (err) {
                    console.log(err);
                }
            };

            fetchData(controller);
        }
        return () => controller.abort();
    }, [localFiltersbyDay, formik.values.settings.provider]);

    return (
        <Card
            title={t("dashboard.byDay.title")}
            ref={refByDay}
            extra={
                <Button
                    type="primary"
                    ref={refDownloadByDay}
                    onClick={() =>
                        download(
                            "by-day",
                            localFiltersbyDay,
                            formik.values.settings.provider!,
                            null,
                        )
                    }
                >
                    {t("shared.download")}
                </Button>
            }
            styles={{
                header: {
                    color: token.colorPrimary,
                    borderLeft: `8px solid ${token.colorPrimary}`,
                },
            }}
        >
            {loadingByDay ? (
                <SkeletonChart type="bar" />
            ) : (
                <StackedBarChart
                    // TODO: Try to pass token colour to theme directly, if it does not work pass the "provider" and use custom themes
                    theme={formik.values.settings.provider!}
                    data={byDay}
                    isStack={true}
                    xField={"label"}
                    yField={"value"}
                    seriesField={"type"}
                />
            )}
        </Card>
    );
};

export default ByDay;
