import {
    DeleteOutlined,
    DollarOutlined,
    MailOutlined,
    PercentageOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Row,
    Tag,
    Tooltip,
    message,
    theme,
} from "antd";
import type { InputRef } from "antd";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import RocketApi from "../../../utils/api/rocket-api";
import capitalizeLetter from "../../../utils/capitalizeLetter";

type ModalAlertsProps = {
    open: boolean;
    onClose: (value: boolean) => void;
    rowData: any;
    editing: boolean;
    reloadTable: any;
    alerts: { [key: string]: any };
};

const ModalAlerts = ({
    open,
    onClose,
    rowData,
    editing,
    reloadTable,
    alerts,
}: ModalAlertsProps) => {
    const [emailTags, setEmailTags] = useState<string[]>([]);
    const [percentageTags, setPercentageTags] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [emailInputVisible, setEmailInputVisible] = useState(false);
    const [emailInputValue, setEmailInputValue] = useState("");
    const [percentageInputVisible, setPercentageInputVisible] = useState(false);
    const [percentageInputValue, setPercentageInputValue] = useState("");

    const [openPop, setOpenPop] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [errorInput, setErrorInput] = useState(true);

    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const [messageApi, contextHolder] = message.useMessage();

    const emailInputRef = useRef<InputRef>(null);
    const editEmailInputRef = useRef<InputRef>(null);
    const editPercentageInputRef: RefObject<HTMLInputElement> | undefined =
        useRef(null);
    const percentageInputRef: RefObject<HTMLInputElement> | undefined =
        useRef(null);

    useEffect(() => {
        if (emailInputVisible) {
            emailInputRef.current?.focus();
        }
        if (percentageInputVisible) {
            percentageInputRef.current?.focus();
        }
    }, [emailInputVisible, percentageInputVisible]);

    useEffect(() => {
        editEmailInputRef.current?.focus();
    }, [emailInputValue]);

    useEffect(() => {
        editPercentageInputRef.current?.focus();
    }, [percentageInputValue]);

    const handleCloseEmailTag = (removedTag: string) => {
        const newEmailTags = emailTags.filter((tag) => tag !== removedTag);
        setEmailTags(newEmailTags);
        setEmailInputValue("");
    };

    const handleClosePercentageTag = (removedTag: string) => {
        const newPercentageTags = percentageTags.filter(
            (tag) => tag !== removedTag,
        );
        setPercentageTags(newPercentageTags);
    };

    const showEmailInput = () => {
        setEmailInputVisible(true);
        setErrorInput(true);
    };

    const showPercentageInput = () => {
        setPercentageInputVisible(true);
    };

    const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailInputValue(e.target.value);
        if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)
        ) {
            setErrorInput(false);
        } else {
            setErrorInput(true);
        }
    };

    const handlePercentageInputChange = (value: any) => {
        setPercentageInputValue(value);
    };

    const handleEmailInputConfirm = () => {
        if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                emailInputValue,
            )
        ) {
            if (emailInputValue && emailTags.indexOf(emailInputValue) === -1) {
                setEmailTags([...emailTags, emailInputValue]);
            }
            setEmailInputVisible(false);
            setEmailInputValue("");
            setErrorInput(false);
        } else {
            if (emailInputValue !== "") {
                messageApi.open({
                    type: "error",
                    content: t("formValidations.messages.validationEmail"),
                });
                setErrorInput(true);
            }
        }
    };

    const handlePercentageInputConfirm = () => {
        if (
            percentageInputValue &&
            percentageTags.indexOf(percentageInputValue) === -1
        ) {
            setPercentageTags([...percentageTags, percentageInputValue]);
        }
        setPercentageInputVisible(false);
        setPercentageInputValue("");
    };

    const tagPlusStyle: React.CSSProperties = {
        background: token.colorBgContainer,
        borderStyle: "dashed",
    };

    const onFinish = (values: any) => {
        const emailValues = emailTags;
        const percentageValues = percentageTags.map((item) => parseFloat(item));

        if (emailValues.length === 0 && emailInputValue.trim() === "") {
            messageApi.open({
                type: "error",
                content: t("formValidations.messages.requiredEmail"),
            });
            setErrorInput(true);
            return;
        }

        if (
            emailInputValue.trim() !== "" &&
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                emailInputValue,
            )
        ) {
            messageApi.open({
                type: "error",
                content: t("formValidations.messages.validationEmail"),
            });
            return;
        }

        if (percentageValues.length === 0) {
            form.setFields([
                {
                    name: "percentage",
                    errors: [
                        t(
                            "dashboard.usages.modals.alerts.formValidations.messages.percentageRequired",
                        ),
                    ],
                },
            ]);
            messageApi.open({
                type: "error",
                content: t(
                    "dashboard.usages.modals.alerts.formValidations.messages.percentageRequired",
                ),
            });
            return;
        }

        const id =
            alerts && rowData
                ? alerts[rowData.id.toLowerCase()]?.id
                : undefined;

        const updatedValues = {
            Amount: parseFloat(values.Amount),
            Description: values.Description,
            ResourceType: rowData.view,
            Provider: rowData.cloud,
            Emails: emailValues,
            Filters: rowData.filters,
            Name: rowData.name,
            ResourceID: rowData.id,
            Percentages: percentageValues,
        };
        createAlert(updatedValues, id);
    };

    const createAlert = async (values: any, id: any): Promise<void> => {
        try {
            setLoading(true);
            const api = new RocketApi();

            const url = editing ? `alerts/${id}` : "alerts";
            const method = editing ? "PUT" : "POST";
            await api.request(url, { method, body: JSON.stringify(values) });

            setLoading(false);
            form.resetFields();
            setEmailTags([]);
            setPercentageTags([]);

            messageApi.open({
                type: "success",
                content: `${t("shared.alert")} ${
                    editing
                        ? t(
                              "dashboard.usages.modals.alerts.formValidations.messages.successCreated",
                          )
                        : t(
                              "dashboard.usages.modals.alerts.formValidations.messages.successEdited",
                          )
                }!`,
            });
            onClose(false);
            reloadTable();
        } catch (err) {
            console.log(err);
            messageApi.open({
                type: "error",
                content: t(
                    "dashboard.usages.modals.alerts.formValidations.messages.errorCreate",
                ),
            });
        }
    };

    useEffect(() => {
        if (open && editing) {
            setEmailTags(alerts[rowData.id.toLowerCase()].emails);
            setPercentageTags(alerts[rowData.id.toLowerCase()].percentages);
            form.setFieldsValue({
                Amount: alerts[rowData.id.toLowerCase()].amount,
                Description: alerts[rowData.id.toLowerCase()].description,
            });
        }
    }, [open, rowData, alerts]);

    const handleCancel = () => {
        onClose(false);
        form.resetFields();
        setEmailTags([]);
        setPercentageTags([]);
        setEmailInputVisible(false);
        setEmailInputValue("");
        setPercentageInputVisible(false);
        setPercentageInputValue("");
    };

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                onFinish(values);
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const handleDelete = async (): Promise<void> => {
        setConfirmLoading(true);

        const id = alerts[rowData.id.toLowerCase()].id;
        const api = new RocketApi();

        try {
            await api.request(`alerts/${id}`, { method: "DELETE" });
        } catch (error) {
            messageApi.open({
                type: "error",
                content: t(
                    "dashboard.usages.modals.alerts.formValidations.messages.errorDelete",
                ),
            });
        } finally {
            setOpenPop(false);
            setConfirmLoading(false);
            // @ts-ignore
            let data = JSON.parse(localStorage.getItem("alerts"));

            if (Object.keys(data).length > 1) {
                delete data[id];
                const alertsUpdate = JSON.stringify(data);
                localStorage.setItem("alerts", alertsUpdate);
            } else {
                localStorage.removeItem("alerts");
            }

            form.resetFields();
            setEmailTags([]);
            setPercentageTags([]);

            messageApi.open({
                type: "success",
                content: t(
                    "dashboard.usages.modals.alerts.formValidations.messages.successDelete",
                ),
            });
            setTimeout(() => {
                onClose(false);
                reloadTable();
            }, 800);
        }
    };

    const showPopconfirm = () => {
        setOpenPop(true);
    };

    const handleCancelDelete = () => {
        setOpenPop(false);
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={t("dashboard.usages.modals.alerts.title")}
                open={open}
                key={`modal_${rowData.id}`}
                okText={editing ? t("shared.edit") : t("shared.create")}
                onCancel={handleCancel}
                onOk={handleOk}
                footer={[
                    editing && (
                        <Popconfirm
                            title={t("formValidations.messages.areUSure")}
                            description={t(
                                "dashboard.usages.modals.alerts.formValidations.messages.confirmDelete",
                            )}
                            open={openPop}
                            onConfirm={handleDelete}
                            okButtonProps={{ loading: confirmLoading }}
                            onCancel={handleCancelDelete}
                        >
                            <Button
                                key="delete"
                                danger
                                onClick={showPopconfirm}
                                icon={<DeleteOutlined />}
                            />
                        </Popconfirm>
                    ),
                    <Button key="back" onClick={handleCancel}>
                        {t("shared.cancel")}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleOk}
                        loading={loading}
                        disabled={errorInput ? true : false}
                    >
                        {editing ? t("shared.edit") : t("shared.create")}
                    </Button>,
                ]}
            >
                {rowData && (
                    <Form form={form} layout="vertical" name="form">
                        <Row gutter={[24, 24]}>
                            <Col span={12}>
                                <Form.Item
                                    label={t(`shared.${rowData.view}`)}
                                    tooltip={t(`shared.${rowData.view}`)}
                                >
                                    <Input
                                        disabled={true}
                                        value={rowData.name}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="Amount"
                                    label={t("formValidations.inputs.value")}
                                    tooltip={t(
                                        "dashboard.usages.modals.alerts.formValidations.messages.valueTooltip",
                                    )}
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                "dashboard.usages.modals.alerts.formValidations.messages.amountRequired",
                                            ),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        addonBefore={<DollarOutlined />}
                                        placeholder={t(
                                            "formValidations.inputs.value",
                                        )}
                                        type="number"
                                        min={1}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="Description"
                                    label={t(
                                        "formValidations.inputs.description",
                                    )}
                                    tooltip={t(
                                        "dashboard.usages.modals.alerts.formValidations.messages.description",
                                    )}
                                >
                                    <Input
                                        placeholder={t(
                                            "formValidations.inputs.description",
                                        )}
                                        type="textarea"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={
                                        <span>
                                            <MailOutlined
                                                style={{ marginRight: "5px" }}
                                            />
                                            {t("formValidations.inputs.emails")}
                                        </span>
                                    }
                                    tooltip={t(
                                        "dashboard.usages.modals.alerts.formValidations.messages.emailTooltip",
                                    )}
                                >
                                    <>
                                        {emailTags.map((tag, index) => {
                                            const isLongTag = tag.length > 20;
                                            const tagElem = (
                                                <Tag
                                                    key={tag}
                                                    closable={true}
                                                    style={{
                                                        userSelect: "none",
                                                    }}
                                                    onClose={() =>
                                                        handleCloseEmailTag(tag)
                                                    }
                                                >
                                                    <span>
                                                        {isLongTag
                                                            ? `${tag.slice(
                                                                  0,
                                                                  20,
                                                              )}...`
                                                            : tag}
                                                    </span>
                                                </Tag>
                                            );
                                            return isLongTag ? (
                                                <Tooltip title={tag} key={tag}>
                                                    {tagElem}
                                                </Tooltip>
                                            ) : (
                                                tagElem
                                            );
                                        })}
                                        {emailInputVisible ? (
                                            <Input
                                                ref={emailInputRef}
                                                type="email"
                                                size="large"
                                                style={{
                                                    width: 300,
                                                    height: 30,
                                                    borderColor: errorInput
                                                        ? "red"
                                                        : "",
                                                }}
                                                value={emailInputValue}
                                                onChange={
                                                    handleEmailInputChange
                                                }
                                                onBlur={handleEmailInputConfirm}
                                                onPressEnter={
                                                    handleEmailInputConfirm
                                                }
                                            />
                                        ) : (
                                            <Tag
                                                style={tagPlusStyle}
                                                onClick={showEmailInput}
                                            >
                                                <PlusOutlined />{" "}
                                                {t("shared.new")} E-mail
                                            </Tag>
                                        )}
                                    </>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={
                                        <span>
                                            <PercentageOutlined
                                                style={{ marginRight: "5px" }}
                                            />
                                            {t(
                                                "dashboard.usages.modals.alerts.formValidations.inputs.percentage",
                                            )}
                                        </span>
                                    }
                                    tooltip={t(
                                        "dashboard.usages.modals.alerts.formValidations.messages.percentageTooltip",
                                    )}
                                >
                                    {percentageTags.map((tag, index) => {
                                        const isLongTag = tag.length > 20;
                                        const tagElem = (
                                            <Tag
                                                key={tag}
                                                closable={true}
                                                style={{ userSelect: "none" }}
                                                onClose={() =>
                                                    handleClosePercentageTag(
                                                        tag,
                                                    )
                                                }
                                            >
                                                <span>
                                                    {isLongTag
                                                        ? `${tag.slice(
                                                              0,
                                                              20,
                                                          )}...`
                                                        : tag}
                                                </span>
                                            </Tag>
                                        );
                                        return isLongTag ? (
                                            <Tooltip title={tag} key={tag}>
                                                {tagElem}
                                            </Tooltip>
                                        ) : (
                                            tagElem
                                        );
                                    })}
                                    {percentageInputVisible ? (
                                        <InputNumber
                                            ref={percentageInputRef}
                                            type="number"
                                            key={`editPercentage`}
                                            size="small"
                                            min={"0"}
                                            max={"100"}
                                            style={{ width: 100, height: 30 }}
                                            value={percentageInputValue}
                                            onChange={
                                                handlePercentageInputChange
                                            }
                                            onBlur={
                                                handlePercentageInputConfirm
                                            }
                                            onPressEnter={
                                                handlePercentageInputConfirm
                                            }
                                        />
                                    ) : (
                                        <Tag
                                            style={tagPlusStyle}
                                            onClick={showPercentageInput}
                                        >
                                            <PlusOutlined /> {t("shared.new")}{" "}
                                            {t(
                                                "dashboard.usages.modals.alerts.formValidations.inputs.percentage",
                                            )}
                                        </Tag>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Modal>
        </>
    );
};

export default ModalAlerts;
