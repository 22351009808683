import { Collapse } from "antd";
import { useFormikContext } from "formik";
import { useEffect } from "react";

import { InitialValues } from "../rocket/types";

const { Panel } = Collapse;

const RenderState = () => {
    const { values } = useFormikContext<InitialValues>();

    return (
        <Collapse>
            <Panel header="Debug" key="debug.formik.values">
                <pre>{JSON.stringify(values, null, 2)}</pre>
            </Panel>
        </Collapse>
    );
};

export default RenderState;
