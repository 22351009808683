import React, { ErrorInfo } from "react";

import Error from "../../../components/ui/errorTpl";

export default class ErrorBoundary extends React.Component<
    {},
    { hasError: boolean }
> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        console.log("error", error);
    }

    render() {
        if (this.state.hasError) {
            return <Error />;
        }
        //@ts-ignore
        return this.props.children;
    }
}
