import RocketApi from "./api/rocket-api";
import { message } from "antd";

const download = (type: string, appliedFilters: any, cloud: string, id: any) => {
  const api = new RocketApi();
  const fetchData = async (): Promise<void> => {
    // Filtra os filtros aplicados e remove o filtro "view"
    const filtered = appliedFilters.reduce(
      (result: { [key: string]: string }, { key, value }: any) => {
        if (key !== "view" && value !== "") {
          result[key] = value;
        }
        return result;
      },
      {}
    );

    // Converte os filtros filtrados em uma string
    const filteredString = Object.entries(filtered)
      .map(([key, value]) => `${key}:${value}`)
      .join("-");

    const nameFile = `download-${type}-${filteredString}`;

    try {
      const data = await api.requestDownload(
        `${cloud}/${type === "reports" ? "downloads" : "download"}/${
          type
        }/${id ? id : ""}`,
        JSON.stringify({ filters: appliedFilters })
      );

      if (typeof data === "string" && data !== "Not Found") {
        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", `${nameFile}.csv`);
        a.click();
      } else if (typeof data !== "string" || data !== "Not Found") {
        const a = document.createElement("a");
        a.setAttribute("href", data.url.split("?")[0]);
        a.setAttribute("download", data.url.split("?")[0]);
        a.click();
      } else {
        message.error("Erro ao fazer o download. "+data);
        console.log("erro");
      }
    } catch (err) {
      message.error("Erro ao fazer o download.");
      console.log(err);
    }
  };
  fetchData();
};

export default download;
