import { Alert, Col, Row, theme } from "antd";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Filters from "../../components/rocket/Filters";
import RecommendationsTable from "../../components/rocket/RecommendationsTable";
import {
    Indicators,
    InitialValues,
    KeyValuePair,
} from "../../components/rocket/types";
import { useTitleSettings } from "../../context/TitleContext";
import RocketApi from "../../utils/api/rocket-api";
import AppliedFilters from "./ui/appliedFilters";
import ByDay from "./ui/byDay";
import ByMonth from "./ui/byMonth";
import ErrorBoundary from "./ui/errorBoundary";
import QuantityIndicators from "./ui/quantityIndicators";
import Reports from "./ui/reports";
import ResourcePie from "./ui/resourcePie";
import UsageDetails from "./ui/usageDetails";
import ValueIndicators from "./ui/valuesIndicators";

const Dashboard = () => {
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [indicators, setIndicators] = useState<Indicators>({
        actual: 0,
        last: 0,
        forecast: 0,
        actual_quantity: 0,
        last_quantity: 0,
        lastExchangeRate: null,
        exchangeRate: null,
    });
    const [view, setView] = useState("");
    const [localFilters, setLocalFilters] = useState<KeyValuePair[]>([]);
    const [notification, setNotification] = useState(
        localStorage.getItem("isCurrent") === "true",
    );
    const [msgCurrentZero, setMsgCurrentZero] = useState(false);
    const formik = useFormikContext<InitialValues>();
    const { setFilterComponent } = useTitleSettings();
    const { t } = useTranslation();

    useEffect(() => {
        let filters: KeyValuePair[] = [];
        for (const key in formik.values.filters) {
            // @ts-ignore
            if (Array.isArray(formik.values.filters[key])) {
                // @ts-ignore
                for (const item of formik.values.filters[key] || []) {
                    filters.push({ key, value: item });
                }
            } else {
                filters.push({
                    key,
                    // @ts-ignore
                    value: formik.values.filters[key] || "",
                });
            }
        }
        if (formik.values.settings.provider === "aws") {
            const params = new URLSearchParams(window.location.search);
            if (params) {
                for (const [key, value] of params) {
                    if (key === "margin") {
                        filters.push({ key, value });
                    }
                }
            }
        }
        setLocalFilters(filters);
        if (formik.values.filters.hasOwnProperty("view")) {
            // @ts-ignore
            setView(formik.values.filters.view);
        }
        if (
            formik.values.settings.provider === "azure" ||
            formik.values.settings.provider === "azuredw"
        ) {
            setMsgCurrentZero(true);
        }
    }, [formik.values.filters, formik.values.settings.provider]);

    useEffect(() => {
        // @ts-ignore
        setNotification(localStorage.getItem("isCurrent") === "true");
    }, [localStorage.getItem("isCurrent")]);

    const fetchIndicators = async (controller: AbortController) => {
        try {
            setLoadingDashboard(true);
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/indicators`,
                {
                    body: JSON.stringify({ filters: localFilters }),
                    controller,
                },
            );

            const data = await response?.json();

            setIndicators(data);
            setLoadingDashboard(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        if (localFilters.length > 0) {
            fetchIndicators(controller);
        }
        return () => controller.abort();
    }, [localFilters, formik.values.settings.provider]);

    useEffect(() => {
        setFilterComponent(<Filters />);
    }, []);

    return (
        <ErrorBoundary>
            <>
                {notification && (
                    <Alert
                        message={t("dashboard.messages.costsCurrent")}
                        type="info"
                        showIcon
                        style={{ marginBottom: 15 }}
                    />
                )}
                {notification && indicators.actual === 0 && msgCurrentZero && (
                    <Alert
                        message={t("dashboard.messages.delayCurrent")}
                        type="info"
                        showIcon
                        style={{ marginBottom: 15 }}
                    />
                )}
                <Row gutter={[24, 24]}>
                    <AppliedFilters />

                    <Col xs={24} md={16} xl={16}>
                        <ValueIndicators
                            loading={loadingDashboard}
                            indicators={indicators}
                            type=""
                            currency={formik.values.settings.currencyCode}
                        />
                    </Col>
                    <Col xs={24} md={8} xl={8}>
                        <QuantityIndicators
                            loading={loadingDashboard}
                            indicators={indicators}
                            view={view}
                            type=""
                        />
                    </Col>

                    <Col span={24}>
                        <UsageDetails />
                    </Col>

                    <Col xs={24} xl={12}>
                        <ByMonth />
                    </Col>
                    <Col xs={24} xl={12}>
                        <ResourcePie />
                    </Col>

                    <Col span={24}>
                        <ByDay />
                    </Col>
                    {formik.values.settings.provider === "aws" && (
                        <Col span={24}>
                            <RecommendationsTable />
                        </Col>
                    )}

                    <AppliedFilters />

                    <Col span={24}>
                        <Reports />
                    </Col>
                </Row>
            </>
        </ErrorBoundary>
    );
};

export default Dashboard;
