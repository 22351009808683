import { Card, Tabs, theme } from "antd";
import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import PieChart from "../../../components/rocket/PieChart";
import { KeyValuePair } from "../../../components/rocket/types";
import useLocalStorageValue from "../../../hooks/useLocalStorage";
import useNamedRef from "../../../hooks/useNamedRef";
import RocketApi from "../../../utils/api/rocket-api";
import formatCurrency from "../../../utils/formatCurrency";

const { useToken } = theme;

const ResourcePieLicenses = () => {
    const { token } = useToken();
    const formik = useFormikContext<any>();
    const { t, i18n } = useTranslation();

    const refResources = useNamedRef("resources");
    const refByChargeTypes = useNamedRef("chargetypes");
    const refByBillingCycles = useNamedRef("billingcycles");
    const refByStatus = useNamedRef("status");
    const refByResources = useNamedRef("byResources");

    const [activeTabKey, setActiveTabKey] = useState<string | undefined>(
        undefined,
    );

    const key = useLocalStorageValue("step");
    useEffect(() => {
        switch (key) {
            case "15":
                setActiveTabKey("Resources");
                break;
            case "16":
                setActiveTabKey("byCategory");
                break;
            case "17":
                setActiveTabKey("Region");
                break;
            case "18":
                setActiveTabKey("byTags");
                break;
            case "19":
                setActiveTabKey("byPublisher");
                break;
            case "20":
                setActiveTabKey("byVMs");
                break;
            default:
                setActiveTabKey(undefined);
                break;
        }
    }, [key]);

    const [loadingResources, setLoadingResources] = useState(true);
    const [loadingChargeTypes, setLoadingChargeTypes] = useState(true);
    const [loadingBillingCycles, setLoadingBillingCycles] = useState(true);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [products, setProducts] = useState<any[] | null>(null);
    const [chargeTypes, setChargeTypes] = useState<any[] | null>(null);
    const [billingCycles, setBillingCycles] = useState<any[] | null>(null);
    const [status, setStatus] = useState<any[] | null>(null);
    const [isVisibleByPublisher, setIsVisibleByPublisher] = useState(false);
    const [localFiltersbyTags, setLocalFiltersbyTags] = useState<
        KeyValuePair[]
    >([]);

    useEffect(() => {
        let filters: KeyValuePair[] = [];
        for (const key in formik.values.filters) {
            // @ts-ignore
            if (Array.isArray(formik.values.filters[key])) {
                // @ts-ignore
                for (const item of formik.values.filters[key]) {
                    filters.push({ key, value: item });
                }
            } else {
                // @ts-ignore
                filters.push({ key, value: formik.values.filters[key] });
            }
        }
        if (formik.values.settings.provider === "aws") {
            const params = new URLSearchParams(window.location.search);
            for (const [key, value] of params) {
                if (key === "margin") {
                    filters.push({ key, value });
                }
            }
        }
        setLocalFiltersbyTags(filters);
        if (formik.values.filters.catalog) {
            setIsVisibleByPublisher(
                formik.values.filters.catalog.includes("marketplace"),
            );
        }
    }, [formik.values.filters]);

    const limitChartData = (data: any, limit: number) => {
        if (data.length <= limit) {
            return data;
        }
        const sortedData = data.sort((a: any, b: any) => b.value - a.value);
        const topData = sortedData.slice(0, limit);
        const sumOthers = sortedData
            .slice(limit)
            .reduce((sum: any, item: any) => sum + item.value, 0);
        const othersData = { name: "Others", value: sumOthers };
        return [...topData, othersData];
    };

    const limitChartDataWithChildren = (data: any, limit: number) => {
        if (data.length <= limit) {
            return data;
        }

        const sortedData = data.sort(
            (a: { value: number }, b: { value: number }) => b.value - a.value,
        );
        const topData = sortedData.slice(0, limit);
        const remainingData = sortedData.slice(limit);

        if (remainingData.length > limit) {
            const sumOfOthers = remainingData.reduce(
                (sum: any, item: { value: any }) => sum + item.value,
                0,
            );
            const othersData = {
                name: "Others",
                value: sumOfOthers,
                children: remainingData.slice(0, limit),
            };
            othersData.children = limitChartDataWithChildren(
                othersData.children,
                limit,
            );
            topData.push(othersData);
        } else {
            topData.push(...remainingData);
        }

        topData.forEach((item: { children: any }) => {
            if (item.children) {
                item.children = limitChartDataWithChildren(
                    item.children,
                    limit,
                );
            }
        });

        // Quando for 'Others'
        const othersIndex = topData.findIndex(
            (item: { name: string }) => item.name === "Others",
        );
        if (othersIndex !== -1) {
            const othersData = {
                name: "Others",
                value: sortedData
                    .slice(limit)
                    .reduce(
                        (sum: any, item: { value: any }) => sum + item.value,
                        0,
                    ),
                children: sortedData.slice(limit),
            };
            topData[othersIndex].children = limitChartDataWithChildren(
                othersData.children,
                limit,
            );
        }

        return topData;
    };

    const fetchDataProducts = async (controller: AbortController) => {
        try {
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/by-products`,
                {
                    body: JSON.stringify({ filters: localFiltersbyTags }),
                    controller,
                },
            );
            if (response && response.status === 200) {
                const data = await response.json();
                const formattedData = data
                    .map((obj: { value: any; label: any }) => ({
                        ...obj,
                        value: Math.abs(obj.value),
                        name: `${obj.label}: ${formatCurrency(
                            formik.values.settings.currencyCode,
                            obj.value!,
                            i18n.language,
                        )}`,
                    }))
                    .filter((obj: { value: number }) => obj.value !== 0);
                setProducts(limitChartData(formattedData, 20));
            }
        } catch (err) {
            console.log(err);
            setProducts(null);
        } finally {
            setLoadingResources(false);
        }
    };

    const fetchDataChargeTypes = async (controller: AbortController) => {
        try {
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/by-charge-types`,
                {
                    body: JSON.stringify({ filters: localFiltersbyTags }),
                    controller,
                },
            );
            if (response && response.status === 200) {
                const data = await response.json();
                const formattedData = data
                    .map((obj: { value: any; label: any }) => ({
                        ...obj,
                        value: Math.abs(obj.value),
                        name: `${obj.label}: ${formatCurrency(
                            formik.values.settings.currencyCode,
                            obj.value!,
                            i18n.language,
                        )}`,
                    }))
                    .filter((obj: { value: number }) => obj.value !== 0);
                setChargeTypes(limitChartData(formattedData, 20));
            }
        } catch (err) {
            console.log(err);
            setProducts(null);
        } finally {
            setLoadingChargeTypes(false);
        }
    };

    const fetchDataBillingCycles = async (controller: AbortController) => {
        try {
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/by-billing-cycles`,
                {
                    body: JSON.stringify({ filters: localFiltersbyTags }),
                    controller,
                },
            );
            if (response && response.status === 200) {
                const data = await response.json();
                const formattedData = data
                    .map((obj: { value: any; label: any }) => ({
                        ...obj,
                        value: Math.abs(obj.value),
                        name: `${obj.label}: ${formatCurrency(
                            formik.values.settings.currencyCode,
                            obj.value!,
                            i18n.language,
                        )}`,
                    }))
                    .filter((obj: { value: number }) => obj.value !== 0);
                setBillingCycles(limitChartData(formattedData, 20));
            }
        } catch (err) {
            console.log(err);
            setChargeTypes(null);
        } finally {
            setLoadingBillingCycles(false);
        }
    };

    const fetchDataStatus = async (controller: AbortController) => {
        try {
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/by-status`,
                {
                    body: JSON.stringify({ filters: localFiltersbyTags }),
                    controller,
                },
            );
            if (response && response.status === 200) {
                const data = await response.json();
                const formattedData = data
                    .map((obj: { value: any; label: any }) => ({
                        ...obj,
                        value: Math.abs(obj.value),
                        name: `${obj.label}: ${formatCurrency(
                            formik.values.settings.currencyCode,
                            obj.value!,
                            i18n.language,
                        )}`,
                    }))
                    .filter((obj: { value: number }) => obj.value !== 0);
                setStatus(limitChartData(formattedData, 20));
            }
        } catch (err) {
            console.log(err);
            setStatus(null);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        if (localFiltersbyTags.length > 0) {
            setLoadingResources(true);
            setLoadingChargeTypes(true);
            setLoadingBillingCycles(true);
            setLoadingStatus(true);
            fetchDataProducts(controller);
            fetchDataChargeTypes(controller);
            fetchDataBillingCycles(controller);
            fetchDataStatus(controller);
        }
        return () => controller.abort();
    }, [localFiltersbyTags]);

    const tabItems = useMemo(
        () => [
            {
                key: "Resources",
                tab: "By Resources",
                ref: refByResources,
                label: "By Products",
                children: (
                    <PieChart
                        theme={formik.values.settings.provider!}
                        data={products || []}
                        type="licenses"
                        loading={loadingResources}
                    />
                ),
                loading: loadingResources,
            },
            {
                key: "chargeTypes",
                tab: "By Charge Types",
                ref: refByChargeTypes,
                label: "By Charge Types",
                children: chargeTypes ? (
                    <PieChart
                        theme={formik.values.settings.provider!}
                        data={chargeTypes || []}
                        type="licenses"
                        loading={loadingChargeTypes}
                    />
                ) : null,
                loading: loadingChargeTypes,
            },
            {
                key: "BillingCycles",
                tab: "By BillingCycles",
                ref: refByBillingCycles,
                label: "By Billing Cycles",
                children: billingCycles ? (
                    <PieChart
                        theme={formik.values.settings.provider!}
                        data={billingCycles || []}
                        type="licenses"
                        loading={loadingBillingCycles}
                    />
                ) : null,
                loading: loadingBillingCycles,
            },
            {
                key: "Status",
                tab: "By Status",
                ref: refByStatus,
                label: "By Status",
                children: status ? (
                    <PieChart
                        theme={formik.values.settings.provider!}
                        data={status || []}
                        type="licenses"
                        loading={loadingStatus}
                    />
                ) : null,
                loading: loadingStatus,
            },
        ],
        [
            products,
            loadingResources,
            loadingChargeTypes,
            loadingBillingCycles,
            loadingStatus,
            billingCycles,
            chargeTypes,
            status,
            formik.values.settings.provider,
            t,
        ],
    );

    const filteredTabItems = useMemo(
        () =>
            tabItems.filter(
                (item) => item.children !== null && item.children !== undefined,
            ),
        [tabItems],
    );

    return (
        <Card
            title={t("dashboard.resourcePie.title")}
            ref={refResources}
            styles={{
                header: {
                    color: token.colorPrimary,
                    borderLeft: `8px solid ${token.colorPrimary}`,
                },
            }}
            style={{ height: "100%" }}
        >
            {filteredTabItems.length > 0 && (
                <Tabs activeKey={activeTabKey}>
                    {filteredTabItems.map((item) => (
                        <Tabs.TabPane
                            tab={<div ref={item.ref}>{item.label}</div>}
                            key={item.key}
                        >
                            <div>{item.children}</div>
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            )}
        </Card>
    );
};

export default ResourcePieLicenses;
