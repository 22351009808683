import { CodeOutlined, DeleteOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Popconfirm,
    Row,
    Select,
    message,
    theme,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import RocketApi from "../../../utils/api/rocket-api";

interface ArgsItem {
    name: string;
    value: string;
}

type ModalCommandsProps = {
    open: boolean;
    onClose: (value: boolean) => void;
    rowData: any;
    editing: boolean;
    reloadTable: any;
    commands: any;
};

const ModalCommands = ({
    open,
    onClose,
    rowData,
    editing,
    reloadTable,
    commands,
}: ModalCommandsProps) => {
    const [configCommands, setConfigCommands] = useState<
        { value: string; label: string }[]
    >([]);
    const [commandsData, setCommandsData] = useState<
        { id: string; name: string; possibleArgs: string[] }[]
    >([]);
    const [loading, setLoading] = useState(false);
    const [openPop, setOpenPop] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [selectedCommandPossibleArgs, setSelectedCommandPossibleArgs] =
        useState<string[]>([]);
    const [selectedCommand, setSelectedCommand] = useState<{} | null>(null);
    const [clone, setClone] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const api = new RocketApi();

    useEffect(() => {
        const controller = new AbortController();
        if (open) {
            fetchConfigCommands(controller);
        }
        return () => controller.abort();
    }, [open]);

    const fetchConfigCommands = async (controller: AbortController) => {
        try {
            setLoading(true);

            const response = await api.request("configuration/commands", {
                method: "GET",
                controller,
            });
            const dataConfig = await response?.json();
            const configs = dataConfig.items.map(
                (item: { id: any; name: any }) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                },
            );
            setLoading(false);
            setCommandsData(dataConfig.items);
            setConfigCommands(configs);
        } catch (err) {
            console.log(err);
            messageApi.open({
                type: "error",
                content: t("admin.commands.modal.messages.errorGet"),
            });
        }
    };

    const onFinish = (values: any) => {
        const argsArray = Object.keys(values).filter(
            (key) => key !== "optionId" && values[key] !== undefined,
        );
        const valuesArray = argsArray.map((key) => values[key]);

        const updatedValues = {
            optionId: values.optionId,
            //@ts-ignore
            description: !editing ? selectedCommand.label : rowData.option.Name,
            args: valuesArray,
        };
        createCommand(updatedValues);
    };

    const createCommand = async (values: any): Promise<void> => {
        try {
            setLoading(true);

            const url = "commands";
            const method = "POST";
            const response = await api.request(url, {
                method,
                body: JSON.stringify(values),
            });
            if (response?.status === 200) {
                form.resetFields();
                messageApi.open({
                    type: "success",
                    content: t("admin.commands.modal.messages.successCreate"),
                });
                setClone(false);
                onClose(false);
                reloadTable();
            } else {
                messageApi.open({
                    type: "error",
                    content: t("admin.commands.modal.messages.errorCreate"),
                });
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            messageApi.open({
                type: "error",
                content: t("admin.commands.modal.messages.errorCreate"),
            });
        }
    };

    useEffect(() => {
        if (open && editing) {
            const { args, option } = rowData;
            const { PossibleArgs } = option;

            const argsArray = PossibleArgs.map(
                (arg: string, index: number) => ({
                    name: arg,
                    value: args[index],
                }),
            );
            setSelectedCommandPossibleArgs(PossibleArgs);

            const formValues: { [key: string]: any } = {
                optionId: rowData.option.ID,
            };

            //@ts-ignore
            argsArray.forEach(({ name, value }) => {
                formValues[name] = value;
            });
            form.setFieldsValue(formValues);
        }
    }, [open, rowData, commands]);

    const handleCancel = () => {
        onClose(false);
        setClone(false);
        setSelectedCommandPossibleArgs([]);
        form.resetFields();
    };

    const handleOk = () => {
        if (editing && !clone) {
            onClose(false);

            setTimeout(() => {
                setClone(true);
                onClose(true);
            }, 500);
        } else {
            setClone(false);
            form.validateFields()
                .then((values) => {
                    onFinish(values);
                })
                .catch((info) => {
                    console.log("Validate Failed:", info);
                });
        }
    };

    const handleDelete = async (): Promise<void> => {
        setConfirmLoading(true);
        const id = rowData.id;
        const api = new RocketApi();

        try {
            const response = await api.request(`commands/${id}`, {
                method: "DELETE",
            });
            if (response?.status === 200) {
                setOpenPop(false);
                setConfirmLoading(false);

                messageApi.open({
                    type: "success",
                    content: t("admin.commands.modal.messages.successDelete"),
                });

                setTimeout(() => {
                    onClose(false);
                    reloadTable();
                }, 1000);
            } else {
                setOpenPop(false);
                setConfirmLoading(false);
                messageApi.open({
                    type: "error",
                    content: t("admin.commands.modal.messages.errorDelete"),
                });
            }
            setLoading(false);
        } catch (error) {
            messageApi.open({
                type: "error",
                content: t("admin.commands.modal.messages.genericError"),
            });
        }
    };

    const showPopconfirm = () => {
        setOpenPop(true);
    };

    const handleCancelDelete = () => {
        setOpenPop(false);
    };

    useEffect(() => {
        if (selectedCommand) {
            const selectedCommandData = commandsData.find(
                //@ts-ignore
                (command) => command.id === selectedCommand.value,
            );
            if (selectedCommandData && selectedCommandData.possibleArgs) {
                setSelectedCommandPossibleArgs(
                    selectedCommandData.possibleArgs,
                );
            }
        }
    }, [selectedCommand, commands]);

    return (
        <>
            {contextHolder}
            <Modal
                title={`${
                    editing && !clone ? t("shared.clone") : t("shared.create")
                }`}
                open={open}
                key={`modal_${rowData.id}`}
                okText={`${editing ? t("shared.clone") : t("shared.create")}`}
                onCancel={handleCancel}
                onOk={handleOk}
                footer={[
                    editing && rowData.status !== "PROCESSING" && (
                        <Popconfirm
                            title={t("formValidations.messages.areUSure")}
                            description={t(
                                "admin.commands.modal.messages.confirmDelete",
                            )}
                            open={openPop}
                            onConfirm={handleDelete}
                            okButtonProps={{ loading: confirmLoading }}
                            onCancel={handleCancelDelete}
                        >
                            <Button
                                key="delete"
                                danger
                                onClick={showPopconfirm}
                                icon={<DeleteOutlined />}
                            />
                        </Popconfirm>
                    ),
                    <Button key="back" onClick={handleCancel}>
                        {t("shared.cancel")}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleOk}
                        loading={loading}
                    >
                        {editing && !clone
                            ? t("shared.clone")
                            : t("shared.create")}
                    </Button>,
                ]}
            >
                {rowData && (
                    <Form form={form} layout="vertical" name="form">
                        <Row gutter={[24, 24]}>
                            {editing && !clone && (
                                <Col span={24}>
                                    <Form.Item
                                        name="commandId"
                                        label="ID"
                                        initialValue={rowData.id}
                                    >
                                        <Input
                                            disabled={editing}
                                            key={rowData.id}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            <Col span={12}>
                                <Form.Item
                                    name="optionId"
                                    label={t("admin.commands.title")}
                                    tooltip="Commands"
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                "admin.commands.modal.formValidations.optionRequired",
                                            ),
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={editing && !clone}
                                        options={configCommands}
                                        onChange={(value, option) => {
                                            setSelectedCommand(option);
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <h3>Args</h3>
                                {selectedCommandPossibleArgs.map(
                                    (arg, index) => (
                                        <Form.Item
                                            name={arg}
                                            label={
                                                <span>
                                                    <CodeOutlined
                                                        style={{
                                                            marginRight: "5px",
                                                        }}
                                                    />
                                                    {arg}
                                                </span>
                                            }
                                            key={arg}
                                            tooltip={`Tip ${arg}`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `${arg} ${t(
                                                        "formValidations.messages.inputsRequired",
                                                    )}`,
                                                },
                                            ]}
                                        >
                                            <Input
                                                disabled={editing && !clone}
                                                key={arg}
                                            />
                                        </Form.Item>
                                    ),
                                )}
                            </Col>
                        </Row>
                    </Form>
                )}
            </Modal>
        </>
    );
};

export default ModalCommands;
