import {
    DownloadOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import { Button, Card, Empty, Table, Tooltip, Typography, theme } from "antd";
import { ColumnType } from "antd/es/table";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { InitialValues, KeyValuePair } from "../../../components/rocket/types";
import useNamedRef from "../../../hooks/useNamedRef";
import RocketApi from "../../../utils/api/rocket-api";
import capitalizeLetter from "../../../utils/capitalizeLetter";
import download from "../../../utils/download";
import ModalReports from "../ui/modalReports";

const { Text } = Typography;

const { useToken } = theme;

interface DataItem {
    id: string;
    createdAt: string;
    status: string;
}

const Reports = () => {
    const formik = useFormikContext<InitialValues>();
    const { t } = useTranslation();
    const { token } = useToken();

    const refReports = useNamedRef("reports");
    const refDownloadReports = useNamedRef("downloadReports");
    const refReloadReports = useNamedRef("reloadReports");

    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(false);
    const [localFilters, setLocalFilters] = useState<KeyValuePair[]>([]);

    useEffect(() => {
        let filters: KeyValuePair[] = [];
        for (const key in formik.values.filters) {
            // @ts-ignore
            if (Array.isArray(formik.values.filters[key])) {
                // @ts-ignore
                for (const item of formik.values.filters[key]) {
                    filters.push({ key, value: item });
                }
            } else {
                // @ts-ignore
                filters.push({ key, value: formik.values.filters[key] });
            }
        }
        if (formik.values.settings.provider === "aws") {
            const params = new URLSearchParams(window.location.search);
            for (const [key, value] of params) {
                if (key === "margin") {
                    filters.push({ key, value });
                }
            }
        }
        setLocalFilters(filters);
    }, [formik.values.filters]);

    const fetchData = async (controller: AbortController): Promise<void> => {
        try {
            setLoading(true);
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/download/reports`,
                {
                    method: "GET",
                    controller,
                },
            );
            const data = await response?.json();
            setReports(data.items);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        if (localFilters.length > 0) {
            fetchData(controller);
        }
        return () => controller.abort();
    }, [localFilters]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        setIsModalVisible(true);
    };

    const columns: ColumnType<DataItem>[] = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            sorter: (a, b) => a.id.localeCompare(b.id),
            sortDirections: ["descend", "ascend"],
            render: (value, record, index) => {
                return (
                    <>
                        {record.status !== "finished" ? (
                            <span id={value}>{value}</span>
                        ) : (
                            <Tooltip title={`Download report`}>
                                <span id={value}>
                                    {value}
                                    <DownloadOutlined className="reports-download-icon" />
                                </span>
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            ellipsis: true,
            width: "20%",
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortDirections: ["descend", "ascend"],
            render: (value, row, index) => {
                let color: string = "#4CAF50";

                switch (value) {
                    case "waiting":
                        color = "#ebd430";
                        break;

                    case "processing":
                        color = "#2196F3";
                        break;

                    case "error":
                        color = "#F44336";
                        break;

                    case "expired":
                        color = "#ddd";
                        break;

                    case "failed":
                        color = "#F44336";
                        break;

                    default:
                        break;
                }

                return (
                    <div>
                        <Button
                            disabled={value !== "finished"}
                            style={{
                                background: color,
                                color: "#fff",
                                borderColor: color,
                            }}
                        >
                            {t(`dashboard.reports.status.${value}`)}
                        </Button>
                    </div>
                );
            },
        },
        {
            title: t("dashboard.reports.tableTitles.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
            sortDirections: ["descend", "ascend"],
        },
    ];

    const reloadTableActions = () => {
        const controller = new AbortController();
        fetchData(controller);
    };

    return (
        <Card
            ref={refReports}
            title={
                <>
                    <Tooltip
                        title={t("dashboard.reports.messages.about")}
                        placement="topLeft"
                    >
                        <Text
                            style={{ color: token.colorPrimary, fontSize: 16 }}
                        >
                            {t("dashboard.reports.title")}
                            <QuestionCircleOutlined
                                style={{
                                    fontSize: 14,
                                    marginLeft: 5,
                                }}
                            />
                        </Text>
                    </Tooltip>
                </>
            }
            extra={
                <>
                    <Tooltip title={t("dashboard.reports.tooltips.reload")}>
                        <Button
                            ref={refReloadReports}
                            icon={<ReloadOutlined />}
                            onClick={() => {
                                const controller = new AbortController();
                                fetchData(controller);
                            }}
                            style={{ marginRight: 10 }}
                        ></Button>
                    </Tooltip>
                    <Button
                        type="primary"
                        onClick={(e) => showModal(e)}
                        ref={refDownloadReports}
                    >
                        {t("dashboard.reports.btn.generateReport")}
                    </Button>
                </>
            }
            styles={{
                header: {
                    color: token.colorPrimary,
                    borderLeft: `8px solid ${token.colorPrimary}`,
                },
            }}
            className="custom-card-header"
        >
            {reports ? (
                <Table<DataItem>
                    loading={loading}
                    bordered={false}
                    dataSource={reports}
                    rowKey={(row) => row.id}
                    rowClassName={(record, index) => {
                        if (record.status !== "finished") {
                            return "not-allowed-row";
                        }
                        return "";
                    }}
                    columns={columns}
                    pagination={{
                        defaultPageSize: 5,
                        hideOnSinglePage: true,
                        showSizeChanger: false,
                        locale: {
                            prev_page: t("shared.prevPage"),
                            next_page: t("shared.nextPage"),
                        },
                    }}
                    onRow={(record) => {
                        return {
                            onClick: (e) => {
                                if (record.status === "finished") {
                                    download(
                                        "reports",
                                        localFilters,
                                        formik.values.settings.provider!,
                                        record.id,
                                    );
                                } else {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            },
                        };
                    }}
                    scroll={{ x: 500 }}
                    locale={{
                        triggerDesc: t("shared.desc"),
                        triggerAsc: t("shared.asc"),
                        cancelSort: t("shared.cancelSort"),
                    }}
                />
            ) : (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t("dashboard.reports.messages.noData")}
                />
            )}
            <ModalReports
                open={isModalVisible}
                onClose={setIsModalVisible}
                reloadTable={reloadTableActions}
                filters={localFilters}
            />
        </Card>
    );
};

export default Reports;
