import {
    Alert,
    Button,
    Col,
    DatePicker,
    Form,
    Modal,
    Row,
    Select,
    message,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import RocketApi from "../../../utils/api/rocket-api";

type ModalImportProps = {
    open: boolean;
    onClose: (value: boolean) => void;
    provider: string;
    reloadTable: any;
};

const ModalImport = ({
    open,
    onClose,
    provider,
    reloadTable,
}: ModalImportProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [fileCsv, setFileCsv] = useState<File | null>(null);

    const onFinish = (values: any) => {
        const updatedValues = {
            initialDate: dayjs(values.initialDate).format("YYYY-MM-DD"),
            markupType: values.markupType,
            file: fileCsv,
        };
        createMarkup(updatedValues);
    };

    const createMarkup = async (values: {
        initialDate: string;
        markupType: string;
        file: File | null;
    }) => {
        var formdata = new FormData();
        formdata.append("initialDate", values.initialDate);
        formdata.append("markupType", values.markupType);
        if (values.file) {
            formdata.append("file", values.file, values.file.name);
        }

        try {
            setLoading(true);
            const api = new RocketApi();
            const response = await api.requestImport(
                `${provider}/markups/bulk`,
                {
                    body: formdata,
                },
            );
            if (response.status === 200) {
                setLoading(false);
                form.resetFields();

                messageApi.open({
                    type: "success",
                    content: t(
                        "dashboard.usages.modals.markups.formValidations.messages.successCreate",
                    ),
                });
                onClose(false);
                reloadTable();
            } else {
                setLoading(false);

                messageApi.open({
                    type: "error",
                    content: t(
                        "dashboard.usages.modals.markups.formValidations.messages.errorCreate",
                    ),
                });
            }
        } catch (err) {
            setLoading(false);

            messageApi.open({
                type: "error",
                content: t(
                    "dashboard.usages.modals.markups.formValidations.messages.errorCreate",
                ),
            });
        }
    };

    const handleCancel = () => {
        onClose(false);
        setLoading(false);
        form.resetFields();
    };

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                onFinish(values);
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={`${t("shared.add")} ${t(
                    "dashboard.usages.modals.markups.title",
                )}`}
                open={open}
                key={`modal_bulk`}
                okText={"Import"}
                onCancel={handleCancel}
                onOk={handleOk}
                className="modal"
                footer=""
            >
                <>
                    <Alert
                        message={t(
                            "dashboard.usages.modals.markups.messages.alertCreate",
                        )}
                        type="info"
                        showIcon
                        style={{ marginBottom: 15 }}
                    />
                    <Form
                        form={form}
                        layout="vertical"
                        name="form"
                        className="form-markup"
                    >
                        <Row gutter={[24, 24]}>
                            <Col span={12}>
                                <Form.Item
                                    name="initialDate"
                                    label={t(
                                        "dashboard.usages.modals.markups.formValidations.inputs.initialDate",
                                    )}
                                    tooltip={t(
                                        "dashboard.usages.modals.markups.tooltips.initialDate",
                                    )}
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                "dashboard.usages.modals.markups.formValidations.messages.initialDateRequired",
                                            ),
                                        },
                                    ]}
                                >
                                    <DatePicker format="YYYY-MM-DD" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="markupType"
                                    label={t(
                                        "dashboard.usages.modals.markups.formValidations.inputs.type",
                                    )}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t(
                                                "dashboard.usages.modals.markups.formValidations.inputs.type",
                                            )} ${t(
                                                "formValidations.messages.inputsRequired",
                                            )}`,
                                        },
                                    ]}
                                >
                                    <Select
                                        options={[
                                            {
                                                label: "Default",
                                                value: "default",
                                            },
                                            {
                                                label: "Brazilian",
                                                value: "brazilian",
                                            },
                                            {
                                                label: "AWS Distributor Discount",
                                                value: "aws_distributor_discount",
                                            },
                                            {
                                                label: "AWS TDSynnex Markup",
                                                value: "aws_markup",
                                            },
                                        ]}
                                    ></Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="file"
                                    label={t(
                                        "dashboard.usages.modals.markups.formValidations.inputs.file",
                                    )}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t(
                                                "dashboard.usages.modals.markups.formValidations.inputs.file",
                                            )} ${t(
                                                "formValidations.messages.inputsRequired",
                                            )}`,
                                        },
                                    ]}
                                >
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            if (e.target.files) {
                                                setFileCsv(e.target.files[0]);
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Button
                                key="back"
                                onClick={handleCancel}
                                style={{ marginRight: 10 }}
                            >
                                {t("shared.cancel")}
                            </Button>
                            <Button
                                key="submit"
                                type="primary"
                                onClick={handleOk}
                                loading={loading}
                            >
                                Import
                            </Button>
                        </Row>
                    </Form>
                </>
            </Modal>
        </>
    );
};

export default ModalImport;
