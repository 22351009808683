export default class RocketApi {
    constructor() {
        this.url =
            process.env.REACT_APP_BACKEND_URL ||
            `${window.location.origin}/rocketsvc/api/v4`;

        this.pathName = process.env.PUBLIC_URL;
    }

    async login(username, password) {
        this.deleteCookie("rocket_aws_session"); //delete old cookie
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });

        var formData = JSON.stringify({
            username: username,
            password: password,
        });

        var requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: formData,
        };
        const response = await fetch(`${this.url}/auth/login`, requestOptions);

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem("access_token", data.token);
            return data.token;
        }
    }

    async getToken() {
        const { token } = await JSON.parse(localStorage.getItem("authTokens"));
        if (token) {
            return token;
        }
    }

    async request(path, options = {}, cache) {
        this.deleteCookie("rocket_aws_session"); // HACK: delete old cookie
        const token = await this.getToken();

        const { headers: headersAppend = {}, ...optionsAppend } = options;

        const defaultHeaders = {
            "Cache-Control": cache ? "no-cache" : "only-if-cached",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        // HACK: If path has an query param, we need to ensure proper encoding
        const pathParts = path.split("?");
        const searchParams = new URLSearchParams(pathParts[1]);
        searchParams.append("timestamp", Date.now());

        const response = await fetch(
            `${this.url}/${pathParts[0]}?${searchParams.toString()}`,
            {
                method: "POST",
                headers: {
                    ...defaultHeaders,
                    ...headersAppend,
                },
                ...optionsAppend,
            },
        );

        if (response.ok) {
            return response;
        } else if (response.status === 401) {
            window.location = `${this.pathName}/login`;
        } else {
            return response;
        }
    }

    async requestDownload(type, filter, id) {
        const token = await this.getToken();

        const response = await fetch(
            `${this.url}/${type}${
                id ? `/${id}` : ""
            }?q=${filter}&timestamp=${Date.now()}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        if (response.ok) {
            if (type.includes("reports")) {
                return response;
            } else {
                const data = await response.text();
                return data;
            }
        } else {
            return response.statusText;
        }
    }

    async requestImport(url, options) {
        this.deleteCookie("rocket_aws_session");
        const token = await this.getToken();

        const { headers: headersAppend = {}, ...optionsAppend } = options;
        const defaultHeaders = {
            Authorization: `Bearer ${token}`,
        };
        const response = await fetch(`${this.url}/${url}`, {
            method: "POST",
            headers: {
                ...defaultHeaders,
                ...headersAppend,
            },
            ...optionsAppend,
        });
        if (response.ok) {
            return response;
        } else {
            return response;
        }
    }

    deleteCookie(cookieName) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
}
