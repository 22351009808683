import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Spin } from "antd";

const Loading = () => {
    return (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
            <Col>
                <Spin
                    indicator={
                        <LoadingOutlined style={{ fontSize: 80 }} spin />
                    }
                />
            </Col>
        </Row>
    );
};

export default Loading;
