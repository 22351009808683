export const tourSteps = (t: any, references: any) => {
    const {
        refFilter,
        refValuesIndicators,
        refValuesIndicatorsPrev,
        refValuesIndicatorsActual,
        refValuesIndicatorsForecast,
        refQuantityIndicators,
        refQuantityIndicatorsPrev,
        refQuantityIndicatorsActual,
        refUsages,
        refDateUsages,
        refViewUsages,
        refDownloadUsages,
        refByMonth,
        refDownloadByMonth,
        refResources,
        refByResources,
        refByCategory,
        refByRegion,
        refByTags,
        refByPublisher,
        refByVms,
        refByDay,
        refDownloadByDay,
        refReports,
        refDownloadReports,
        refReloadReports,
    } = references;

    return [
        {
            title: t("tour.components.filters.title"),
            description: t("tour.components.filters.description"),
            placement: "left",
            target: () => refFilter?.current,
        },
        {
            title: t("tour.components.indicatorsValue.title"),
            description: t("tour.components.indicatorsValue.description"),
            target: () => refValuesIndicators?.current,
        },
        {
            title: t("tour.components.indicatorsValue.components.prev.title"),
            description: t(
                "tour.components.indicatorsValue.components.prev.description",
            ),
            target: () => refValuesIndicatorsPrev?.current,
        },
        {
            title: t("tour.components.indicatorsValue.components.actual.title"),
            description: t(
                "tour.components.indicatorsValue.components.actual.description",
            ),
            target: () => refValuesIndicatorsActual?.current,
        },
        {
            title: t(
                "tour.components.indicatorsValue.components.forecast.title",
            ),
            description: t(
                "tour.components.indicatorsValue.components.forecast.description",
            ),
            target: () => refValuesIndicatorsForecast?.current,
        },
        {
            title: t("tour.components.indicatorsQuantity.title"),
            description: t("tour.components.indicatorsQuantity.description"),
            target: () => refQuantityIndicators?.current,
        },
        {
            title: t(
                "tour.components.indicatorsQuantity.components.prev.title",
            ),
            description: t(
                "tour.components.indicatorsQuantity.components.prev.description",
            ),
            target: () => refQuantityIndicatorsPrev?.current,
        },
        {
            title: t(
                "tour.components.indicatorsQuantity.components.actual.title",
            ),
            description: t(
                "tour.components.indicatorsQuantity.components.actual.description",
            ),
            target: () => refQuantityIndicatorsActual?.current,
        },
        {
            title: t("tour.components.usagesDetails.title"),
            description: t("tour.components.usagesDetails.description"),
            target: () => refUsages?.current,
            placement: "top",
        },
        {
            title: t("tour.components.usagesDetails.components.view.title"),
            description: t(
                "tour.components.usagesDetails.components.view.description",
            ),
            target: () => refViewUsages?.current,
            placement: "bottom",
        },
        {
            title: t(
                "tour.components.usagesDetails.components.filterDate.title",
            ),
            description: t(
                "tour.components.usagesDetails.components.filterDate.description",
            ),
            target: () => refDateUsages?.current,
            placement: "bottom",
        },
        {
            title: t("tour.components.usagesDetails.components.download.title"),
            description: t(
                "tour.components.usagesDetails.components.download.description",
            ),
            target: () => refDownloadUsages?.current,
            placement: "left",
        },
        {
            title: t("tour.components.byMonth.title"),
            description: t("tour.components.byMonth.description"),
            target: () => refByMonth?.current,
        },
        {
            title: t("tour.components.byMonth.components.download.title"),
            description: t(
                "tour.components.byMonth.components.download.description",
            ),
            target: () => refDownloadByMonth?.current,
            placement: "left",
        },
        {
            title: t("tour.components.resources.title"),
            description: t("tour.components.resources.description"),
            target: () => refResources?.current,
        },
        {
            title: t(
                "tour.components.resources.components.tabs.byResources.title",
            ),
            description: t(
                "tour.components.resources.components.tabs.byResources.description",
            ),
            target: () => refByResources?.current,
        },
        {
            title:
                t("dashboard.resourcePie.tabs.prefix") +
                " " +
                t("dashboard.resourcePie.tabs.category"),
            description: t(
                "tour.components.resources.components.tabs.byCategory",
            ),
            target: () => refByCategory?.current,
        },
        {
            title:
                t("dashboard.resourcePie.tabs.prefix") +
                " " +
                t("dashboard.resourcePie.tabs.region"),
            description: t(
                "tour.components.resources.components.tabs.byRegion",
            ),
            target: () => refByRegion?.current,
        },
        {
            title:
                t("dashboard.resourcePie.tabs.prefix") +
                " " +
                t("dashboard.resourcePie.tabs.tags"),
            description: t("tour.components.resources.components.tabs.byTags"),
            target: () => refByTags?.current,
        },
        {
            title:
                t("dashboard.resourcePie.tabs.prefix") +
                " " +
                t("dashboard.resourcePie.tabs.publisher"),
            description: t(
                "tour.components.resources.components.tabs.byPublisher",
            ),
            target: () => refByPublisher?.current,
        },
        {
            title: `${t("dashboard.resourcePie.tabs.prefix")} VMs`,
            description: t("tour.components.resources.components.tabs.byVms"),
            target: () => refByVms?.current,
        },
        {
            title: t("tour.components.byDay.title"),
            description: t("tour.components.byDay.description"),
            target: () => refByDay?.current,
        },
        {
            title: t("tour.components.byDay.components.download.title"),
            description: t(
                "tour.components.byDay.components.download.description",
            ),
            target: () => refDownloadByDay?.current,
            placement: "left",
        },
        {
            title: t("tour.components.reports.title"),
            description: t("tour.components.reports.description"),
            target: () => refReports?.current,
        },
        {
            title: t("tour.components.reports.components.reload.title"),
            description: t(
                "tour.components.reports.components.reload.description",
            ),
            target: () => refReloadReports?.current,
            placement: "left",
        },
        {
            title: t("tour.components.reports.components.download.title"),
            description: t(
                "tour.components.reports.components.download.description",
            ),
            target: () => refDownloadReports?.current,
            placement: "left",
        },
    ];
};
