import { useTranslation } from "react-i18next";

import useNamedRef from "../../../hooks/useNamedRef";

const UsageCardTitle = (filters: any) => {
    const { t } = useTranslation();
    const refViewUsages = useNamedRef("viewUsages");
    const refDateUsages = useNamedRef("dateUsages");
    return (
        <div>
            <span ref={refViewUsages}>
                {/* @ts-ignore */}
                {t("dashboard.usages.title") +
                    ": " +
                    t(`shared.${filters.view}`).toUpperCase()}
            </span>
            <br />
            <span ref={refDateUsages}>
                {/* @ts-ignore */}
                {t("dashboard.usages.periodSelected")}:{" "}
                {filters.period ? filters.period : filters.periodStart}
                {filters.periodEnd && " - "}
                {filters.periodEnd}
            </span>
        </div>
    );
};

export default UsageCardTitle;
