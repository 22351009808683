import { Col, ConfigProvider, Row } from "antd";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
    return (
        <ConfigProvider>
            <Row
                align="middle"
                justify="center"
                style={{ display: "flex", height: "100vh" }}
            >
                <Col>
                    <Outlet />
                </Col>
            </Row>
        </ConfigProvider>
    );
};

export default AuthLayout;
