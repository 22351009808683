import { CheckCircleOutlined } from "@ant-design/icons";
import { Empty, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import formatCurrency from "../../utils/formatCurrency";
import { InitialValues } from "./types";

interface DataItem {
    id: string;
    name: string;
    last: number;
    actual: number;
    forecast: number;
    parent_id: string;
    type: string;
}
interface TableProps {
    data?: DataItem[];
    loading?: boolean;
    provider: string;
}

const VMTable = ({ data, loading, provider }: TableProps) => {
    const { t, i18n } = useTranslation();
    const formik = useFormikContext<InitialValues>();

    const columns: ColumnType<DataItem>[] = [
        {
            title: t("dashboard.usages.tableTitles.name"),
            dataIndex: "name",
            key: "name",
            ellipsis: true,
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"],
        },
        {
            title: t("shared.last"),
            dataIndex: "last",
            key: "last",
            render: (value, row, index) => {
                return (
                    <span>
                        {formatCurrency(
                            formik.values.settings.currencyCode,
                            value,
                            i18n.language,
                        )}
                    </span>
                );
            },
            sorter: (a, b) => a.last - b.last,
        },
        {
            title: t("shared.actual"),
            dataIndex: "actual",
            key: "actual",
            render: (value, row, index) => {
                return (
                    <span>
                        {formatCurrency(
                            formik.values.settings.currencyCode,
                            value,
                            i18n.language,
                        )}
                    </span>
                );
            },
            sorter: (a, b) => a.actual - b.actual,
            defaultSortOrder: "descend",
        },
        {
            title: t("shared.forecast"),
            dataIndex: "forecast",
            key: "forecast",
            render: (value) => {
                return (
                    <span>
                        {formatCurrency(
                            formik.values.settings.currencyCode,
                            value,
                            i18n.language,
                        )}
                    </span>
                );
            },
            sorter: (a, b) => a.forecast - b.forecast,
        },
    ];

    if (provider === "azure") {
        columns.splice(2, 0, {
            title: "RI",
            dataIndex: "type",
            key: "type",
            render: (value) => {
                if (value === "2") {
                    return (
                        <CheckCircleOutlined
                            style={{
                                color: "green",
                                display: "block",
                                margin: "0 auto",
                            }}
                        />
                    );
                }
                return null;
            },
            sorter: (a, b) => a.type.localeCompare(b.type),
            sortDirections: ["ascend", "descend"],
        });
    }

    return (
        <Table<DataItem>
            loading={loading}
            bordered={false}
            dataSource={data}
            rowKey={(row) => `${row.id}-${row.name}-${row.parent_id}`}
            columns={columns}
            locale={{
                triggerDesc: t("shared.desc"),
                triggerAsc: t("shared.asc"),
                cancelSort: t("shared.cancelSort"),
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t("shared.noData")}
                    />
                ),
            }}
            pagination={{
                defaultPageSize: 4,
                hideOnSinglePage: true,
                showSizeChanger: false,
                locale: {
                    prev_page: t("shared.prevPage"),
                    next_page: t("shared.nextPage"),
                },
            }}
            scroll={{ x: 600 }}
        />
    );
};

export default VMTable;
