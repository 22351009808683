import dayjs from "dayjs";

export type IProvider =
    | "azure"
    | "gcp"
    | "ibm"
    | "aws"
    | "azuredw"
    | "azure/licenses"
    | null;

export type BillingCycle = "Annually" | "Monthly";
export type Catalog = "marketplace" | "consumption" | "fee";

type ICatalog = Catalog[];
type IBillingCycle = BillingCycle[];

export interface ISettings {
    provider: IProvider;
    resourcesNames: string;
    lastUpdated: string;
    defaultView: string | null;
    frontend: {
        views: string[];
        filters: string[];
        aliases: string[];
    };
    filtersFields: FilterField[];
    markupView: string | null;
    estimateView: string | null;
    aliasesView: string | null;
    currencyCode: string;
}

export interface KeyValuePair {
    key: string;
    value: string;
}

export interface AppliedFilter {
    id: string;
    name: string;
    view: string;
}

export interface FilterField {
    label: string;
    placeholder: string;
    type: "select" | "input";
    extraConfig: {
        filterKey: string;
        optionsURL: string;
    };
}

export interface Indicators {
    actual: number;
    last: number;
    forecast: number;
    actual_quantity: number;
    last_quantity: number;
    lastExchangeRate: number | null;
    exchangeRate: number | null;
    type?: string | null;
}

export interface Filters {
    period?: string;
    start_time?: string;
    end_time?: string;
    view: any;
}

interface AzureDWSettings extends ISettings {
    defaultCost: string | null;
}
interface AzureLicensesSettings extends ISettings {
    defaultCost: string | null;
}

interface AzureDWFilters extends Filters {
    distributor_id: string;
    reseller_id: string;
    customer_id: string;
    subscription_id: string;
    resource_group: string;
    resource_id: string;
    cost: string;
    invoice_type: string;
    view: TViewAz;
    // catalog: ICatalog;
}

interface AzureFilters extends AzureDWFilters {
    catalog: ICatalog;
}

interface AzureLicensesFilters {
    period: string;
    distributor_id: string;
    reseller_id: string;
    customer_id: string;
    subscription_id: string;
    cost: string;
    view: TViewAzLicenses;
    charge_type: any;
    billing_cycle_type: IBillingCycle;
    // status: string;
}

interface AWSFilters extends Filters {
    organization_id: string;
    account_id: string;
    service_id: string;
    view: TViewsAWS;
    catalog: Catalog[];
}

interface GCPFilters extends Filters {
    reseller_id: string;
    customer_id: string;
    project_id: string;
    service_id: string;
    resource_id: string;
    view: TViewsGCP;
}
export type TViewAz =
    | "distributors"
    | "resellers"
    | "customers"
    | "resourceGroups"
    | "resources"
    | null;

export type TViewAzLicenses =
    | "resellers"
    | "customers"
    | "subscriptions"
    | "chargetypes"
    | null;

export type TViewsAWS = "organizations" | "services" | "resources" | null;

export type TViewsGCP =
    | "distributors"
    | "resellers"
    | "customers"
    | "projects"
    | "services"
    | "resources"
    | null;

export interface InitialValuesAzure {
    filters: AzureFilters;
    appliedFilters: AppliedFilter[];
    settings: AzureDWSettings;
    options: any;
}
export interface InitialValuesAzureDW {
    filters: AzureDWFilters;
    appliedFilters: AppliedFilter[];
    settings: AzureDWSettings;
    options: any;
}

export interface InitialValuesAzureLicenses {
    filters: AzureLicensesFilters;
    appliedFilters: AppliedFilter[];
    settings: AzureLicensesSettings;
    options: any;
}

export interface InitialValuesAWS {
    filters: AWSFilters;
    appliedFilters: AppliedFilter[];
    settings: ISettings;
    options: any;
}

export interface InitialValuesGCP {
    filters: GCPFilters;
    appliedFilters: AppliedFilter[];
    settings: ISettings;
    options: any;
}

export type InitialValues =
    | InitialValuesGCP
    | InitialValuesAzure
    | InitialValuesAzureDW
    | InitialValuesAzureLicenses
    | InitialValuesAWS
    | {
          filters: Filters;
          appliedFilters: AppliedFilter[];
          options: {
              [key: string]: any;
          };
          settings: ISettings;
      };

export const azureDefault: InitialValuesAzure = {
    filters: {
        start_time: dayjs().startOf("month").format("YYYY-MM-DD"),
        end_time: dayjs().endOf("month").format("YYYY-MM-DD"),
        distributor_id: "",
        reseller_id: "",
        customer_id: "",
        subscription_id: "",
        resource_group: "",
        resource_id: "",
        view: null,
        invoice_type: "1",
        cost: "",
        catalog: ["consumption", "marketplace"],
    },
    appliedFilters: [],
    settings: {
        provider: "azure",
        defaultView: null,
        defaultCost: null,
        resourcesNames: "",
        frontend: {
            filters: [],
            views: [],
            aliases: [],
        },
        lastUpdated: "",
        filtersFields: [],
        markupView: "",
        estimateView: "",
        aliasesView: "",
        currencyCode: "",
    },
    options: {},
};

export const azureLicensesDefault: InitialValuesAzureLicenses = {
    filters: {
        period: "D080003Q8Y",
        distributor_id: "",
        reseller_id: "",
        customer_id: "",
        subscription_id: "",
        view: null,
        charge_type: "",
        cost: "",
        billing_cycle_type: ["Annually", "Monthly"],
        // status: "Active",
    },
    appliedFilters: [],
    settings: {
        provider: "azure/licenses",
        defaultView: null,
        defaultCost: null,
        resourcesNames: "",
        frontend: {
            filters: [],
            views: [],
            aliases: [],
        },
        lastUpdated: "",
        filtersFields: [],
        markupView: "",
        estimateView: "",
        aliasesView: "",
        currencyCode: "",
    },
    options: {},
};

export const azureDwDefault: InitialValuesAzureDW = {
    filters: {
        start_time: dayjs().startOf("month").format("YYYY-MM-DD"),
        end_time: dayjs().endOf("month").format("YYYY-MM-DD"),
        distributor_id: "",
        reseller_id: "",
        customer_id: "",
        subscription_id: "",
        resource_group: "",
        resource_id: "",
        view: null,
        invoice_type: "azure_plan",
        cost: "",
        // catalog: ["consumption", "marketplace"],
    },
    appliedFilters: [],
    settings: {
        provider: "azuredw",
        defaultView: null,
        defaultCost: null,
        resourcesNames: "",
        frontend: {
            filters: [],
            views: [],
            aliases: [],
        },
        lastUpdated: "",
        filtersFields: [],
        markupView: "",
        estimateView: "",
        aliasesView: "",
        currencyCode: "",
    },
    options: {},
};

export const gcpDefault: InitialValuesGCP = {
    filters: {
        start_time: dayjs().startOf("month").format("YYYY-MM-DD"),
        end_time: dayjs().endOf("month").format("YYYY-MM-DD"),
        reseller_id: "",
        customer_id: "",
        project_id: "",
        service_id: "",
        resource_id: "",
        view: null,
    },
    appliedFilters: [],
    settings: {
        provider: "gcp",
        defaultView: null,
        resourcesNames: "",
        frontend: {
            filters: [],
            views: [],
            aliases: [],
        },
        lastUpdated: "",
        filtersFields: [],
        markupView: "",
        estimateView: "",
        aliasesView: "",
        currencyCode: "",
    },
    options: {},
};

export const awsDefault: InitialValuesAWS = {
    filters: {
        start_time: dayjs().startOf("month").format("YYYY-MM-DD"),
        end_time: dayjs().endOf("month").format("YYYY-MM-DD"),
        organization_id: "",
        account_id: "",
        service_id: "",
        view: null,
        catalog: ["consumption", "marketplace", "fee"],
    },
    appliedFilters: [],
    settings: {
        provider: "aws",
        defaultView: null,
        resourcesNames: "",
        frontend: {
            filters: [],
            views: [],
            aliases: [],
        },
        lastUpdated: "",
        filtersFields: [],
        markupView: "",
        estimateView: "",
        aliasesView: "",
        currencyCode: "",
    },
    options: {},
};

export const defaultInitialValues: InitialValues = {
    filters: {
        start_time: dayjs().startOf("month").format("YYYY-MM-DD"),
        end_time: dayjs().endOf("month").format("YYYY-MM-DD"),
        view: null,
    },
    appliedFilters: [],
    settings: {
        provider: null,
        defaultView: "",
        resourcesNames: "",
        frontend: {
            filters: [],
            views: [],
            aliases: [],
        },
        lastUpdated: "",
        filtersFields: [],
        markupView: "",
        estimateView: "",
        aliasesView: "",
        currencyCode: "",
    },
    options: {},
};
export const defaultInitialValuesLicenses: InitialValues = {
    filters: {
        period: dayjs().startOf("month").format("YYYY-MM-DD"),
        view: null,
    },
    appliedFilters: [],
    settings: {
        provider: null,
        defaultView: "",
        resourcesNames: "",
        frontend: {
            filters: [],
            views: [],
            aliases: [],
        },
        lastUpdated: "",
        filtersFields: [],
        markupView: "",
        estimateView: "",
        aliasesView: "",
        currencyCode: "",
    },
    options: {},
};

const defaultValuesMap: Record<string, InitialValues> = {
    azure: azureDefault,
    azureLicenses: azureLicensesDefault,
    azuredw: azureDwDefault,
    gcp: gcpDefault,
    aws: awsDefault,
};

export const getDefaultInitialValues = (
    provider: string | null,
): InitialValues => {
    const providerType =
        provider === "azure/licenses" ? "azureLicenses" : provider;
    return provider
        ? defaultValuesMap[providerType || provider]
        : defaultInitialValues;
};
