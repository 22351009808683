import {
    DownOutlined,
    InfoCircleOutlined,
    RedoOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Input,
    InputRef,
    Menu,
    MenuProps,
    Row,
    Space,
    Table,
    Tooltip,
    Typography,
} from "antd";
import type {
    ColumnsType,
    TablePaginationConfig,
    TableProps,
} from "antd/es/table";
import type {
    ColumnType,
    FilterConfirmProps,
    FilterValue,
    SorterResult,
} from "antd/es/table/interface";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import RocketApi from "../../../utils/api/rocket-api";
import ModalImport from "../../Dashboard/ui/modalImport";
import ModalMarkups from "../../Dashboard/ui/modalMarkups";

const { Text } = Typography;

interface DataType {
    resourceId: string;
    status: string;
    createdAt: string;
    initialDate: string;
    distributorMarkup: number;
    distributorMarkupDiscount: number;
    resellerMarkup: number;
    resellerMarkupDiscount: number;
}

type DataIndex = keyof DataType;

const Markups = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalImportVisible, setIsModalImportVisible] = useState(false);

    const [data, setData] = useState<any[]>();
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const { provider } = useParams();

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef<InputRef>(null);

    const api = new RocketApi();
    const { t } = useTranslation();

    const fetchData = async (controller: AbortController): Promise<void> => {
        try {
            setLoading(true);
            const response = await api.request(`${provider!}/markups`, {
                method: "GET",
                controller,
            });
            const data = await response?.json();
            setData(data);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const showModal = (e: any, row: any, editing: boolean) => {
        e.preventDefault();
        e.stopPropagation();

        if (row) {
            setSelectedRow(row);
        } else {
            setSelectedRow({});
        }
        setEditing(editing);
        setIsModalVisible(true);
    };

    const showModalImport = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        setIsModalImportVisible(true);
    };

    const reloadTableActions = () => {
        const controller = new AbortController();
        fetchData(controller);
        return () => controller.abort();
    };

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (
        dataIndex: DataIndex,
    ): ColumnType<DataType> => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`${t("admin.users.btns.search")} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex,
                        )
                    }
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex,
                            )
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("admin.users.btns.search")}
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("admin.users.btns.reset")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        {t("admin.users.btns.filter")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("admin.users.btns.close")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{ color: filtered ? "#1677ff" : undefined }}
            />
        ),

        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) => {
            const isSearchedColumn = searchedColumn === dataIndex;
            if (isSearchedColumn && searchText) {
                return text
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ? (
                    <div>{text}</div>
                ) : null;
            } else {
                return text;
            }
        },
    });

    const columns: ColumnsType<DataType> = [
        {
            title: "ID",
            key: "resourceId",
            dataIndex: "resourceId",
            sorter: (a, b) => a.resourceId.localeCompare(b.resourceId),
            sortDirections: ["descend", "ascend"],
            ...getColumnSearchProps("resourceId"),
        },
        {
            title: t("admin.commands.tableTitles.createdAt"),
            key: "createdAt",
            dataIndex: "createdAt",
            sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
            sortDirections: ["descend", "ascend"],
            render(value, record, index) {
                return dayjs(value).format("YYYY-MM-DD");
            },
        },
        {
            title: t(
                "dashboard.usages.modals.markups.formValidations.inputs.initialDate",
            ),
            key: "initialDate",
            dataIndex: "initialDate",
            sorter: (a, b) => a.initialDate.localeCompare(b.initialDate),
            ...getColumnSearchProps("initialDate"),
            sortDirections: ["descend", "ascend"],
            defaultSortOrder: "descend",
            render(value, record, index) {
                return dayjs(value).format("YYYY-MM-DD");
            },
        },
        {
            title: t(
                "dashboard.usages.modals.markups.formValidations.inputs.distributorMarkup",
            ),
            dataIndex: "distributorMarkup",
            key: "distributorMarkup",
            sorter: (
                a: { distributorMarkup: number },
                b: { distributorMarkup: number },
            ) => a.distributorMarkup - b.distributorMarkup,

            render(value, record, index) {
                return (
                    <Tooltip title={parseFloat(value)}>
                        {parseFloat(value).toFixed(2)}
                        <InfoCircleOutlined
                            style={{ verticalAlign: "super", width: 15 }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: t(
                "dashboard.usages.modals.markups.formValidations.inputs.distributorMarkupDiscount",
            ),
            dataIndex: "distributorMarkupDiscount",
            key: "distributorMarkupDiscount",
            sorter: (
                a: { distributorMarkupDiscount: number },
                b: { distributorMarkupDiscount: number },
            ) => a.distributorMarkupDiscount - b.distributorMarkupDiscount,

            render(value, record, index) {
                return (
                    <Tooltip title={parseFloat(value)}>
                        {parseFloat(value).toFixed(2)}
                        <InfoCircleOutlined
                            style={{ verticalAlign: "super", width: 15 }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: t(
                "dashboard.usages.modals.markups.formValidations.inputs.resellerMarkup",
            ),
            dataIndex: "resellerMarkup",
            key: "resellerMarkup",
            sorter: (
                a: { resellerMarkup: number },
                b: { resellerMarkup: number },
            ) => a.resellerMarkup - b.resellerMarkup,

            render(value, record, index) {
                return (
                    <Tooltip title={parseFloat(value)}>
                        {parseFloat(value).toFixed(2)}
                        <InfoCircleOutlined
                            style={{ verticalAlign: "super", width: 15 }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: t(
                "dashboard.usages.modals.markups.formValidations.inputs.resellerMarkupDiscount",
            ),
            dataIndex: "resellerMarkupDiscount",
            key: "resellerMarkupDiscount",
            sorter: (
                a: { resellerMarkupDiscount: number },
                b: { resellerMarkupDiscount: number },
            ) => a.resellerMarkupDiscount - b.resellerMarkupDiscount,

            render(value, record, index) {
                return (
                    <Tooltip title={parseFloat(value)}>
                        {parseFloat(value).toFixed(2)}
                        <InfoCircleOutlined
                            style={{ verticalAlign: "super", width: 15 }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: t("admin.commands.tableTitles.actions"),
            dataIndex: "additionalActions",
            key: "additionalActions",
            render: (value, row, index) => {
                const items: MenuProps["items"] = [
                    {
                        key: "1",
                        label: (
                            <Button
                                type="link"
                                onClick={(e) => showModal(e, row, true)}
                            >
                                {t("dashboard.usages.btns.manageMarkups")}
                            </Button>
                        ),
                    },
                ];

                return (
                    <Dropdown menu={{ items }} trigger={["hover"]}>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Space>
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const refresh = () => {
        setRefreshIndex((prev) => !prev);
    };

    useEffect(() => {
        const controller = new AbortController();

        fetchData(controller);

        return () => {
            controller.abort();
        };
    }, [refreshIndex, provider]);

    return (
        <>
            <ModalMarkups
                open={isModalVisible}
                onClose={setIsModalVisible}
                rowData={selectedRow}
                editing={editing}
                markups={data}
                isAdmin={true}
                provider={provider!}
            />
            <ModalImport
                open={isModalImportVisible}
                onClose={setIsModalImportVisible}
                provider={provider!}
                reloadTable={reloadTableActions}
            />
            <Row justify="space-between" align="middle">
                <Col>
                    <Text strong>
                        {t("dashboard.usages.btns.manageMarkups")}
                    </Text>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        onClick={(e) => showModalImport(e)}
                        style={{ marginRight: 10 }}
                    >
                        {t("shared.import")}
                    </Button>
                    <Button
                        type="default"
                        onClick={(e) => showModal(e, undefined, false)}
                        style={{ marginRight: 10 }}
                    >
                        {t("shared.create")}
                    </Button>
                    <Button
                        icon={<RedoOutlined />}
                        type="primary"
                        onClick={() => refresh()}
                    />
                </Col>
            </Row>
            <Divider />
            <Table
                columns={columns}
                dataSource={data?.filter((item: DataType) => {
                    //@ts-ignore
                    const columnValue = item[searchedColumn];
                    return (
                        searchedColumn === "" ||
                        searchText === undefined ||
                        searchText === "" ||
                        (columnValue !== undefined &&
                            columnValue
                                ?.toString()
                                ?.toLowerCase()
                                ?.includes(searchText.toLowerCase()))
                    );
                })}
                rowKey={(row) => `${row.id}-${row.updatedAt}`}
                pagination={{
                    pageSize: 15,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                    locale: {
                        prev_page: t("shared.prevPage"),
                        next_page: t("shared.nextPage"),
                    },
                }}
                loading={loading}
                scroll={{ x: 1000 }}
                locale={{
                    triggerDesc: t("shared.desc"),
                    triggerAsc: t("shared.asc"),
                    cancelSort: t("shared.cancelSort"),
                }}
            />
        </>
    );
};

export default Markups;
