import { PieChartOutlined, BarChartOutlined } from "@ant-design/icons";
import { Col, Row, Skeleton } from "antd";

const SkeletonChart = (type: any) => {
    return (
        <Row justify="center" align="middle" style={{ height: type.type === "bar" ? "400px" : "340px"}}>
            <Col>
                <Skeleton.Node active>
                    {type.type === 'bar' ? (
                        <BarChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
                    ) : (
                        <PieChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
                    )}
                    
                </Skeleton.Node>
            </Col>
        </Row>
    );
};

export default SkeletonChart;
