import { FallOutlined, LineOutlined, RiseOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const calculatePercentage = (actual: number, last: number) => {
    if (last == 0) {
        return 0;
    }

    return ((actual - last) * 100) / last;
};

const PercentageIcon = (props: { percentage: number }) => {
    if (props.percentage < 0) {
        return <FallOutlined style={{ color: "red" }} />;
    } else if (props.percentage > 0) {
        return <RiseOutlined style={{ color: "green" }} />;
    }
    return <LineOutlined />;
};

export const Percentage = (props: {
    lastRef: number;
    actualRef: number;
    tooltip: string;
    skip?: boolean;
}) => {
    const percentage = calculatePercentage(props.actualRef, props.lastRef);
    const textColor =
        percentage == 0 ? "gray" : percentage > 0 ? "green" : "red";

    if (props.skip) {
        return null;
    }

    return (
        <>
            <Tooltip title={props.tooltip}>
                <span style={{ color: textColor, fontSize: "14px" }}>
                    <PercentageIcon percentage={percentage} />
                    {`${percentage.toFixed(2)}%`}
                </span>
            </Tooltip>
        </>
    );
};
