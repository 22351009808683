import { Card, Col, Row, Statistic, Tooltip, Tour } from "antd";
import type { TourProps } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Indicators } from "../../../components/rocket/types";
import useNamedRef from "../../../hooks/useNamedRef";
import formatCurrency from "../../../utils/formatCurrency";
import { Percentage } from "./percentage";

const ExchangeRate = (props: { exchangeRate: null | number }) => {
    const [fxRate, setFxRate] = useState<null | Number>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.exchangeRate) {
            setFxRate(1 / props.exchangeRate);
        }
    }, []);

    return (
        <>
            {fxRate && (
                <Tooltip title={t("dashboard.indicators.tooltips.dollar")}>
                    <span style={{ color: "gray", fontSize: "12px" }}>
                        {fxRate.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 4,
                        })}
                    </span>
                </Tooltip>
            )}
        </>
    );
};

const ValueIndicators = (props: {
    indicators: Indicators;
    loading: boolean;
    type: string;
    currency: string;
}) => {
    const { t, i18n } = useTranslation();
    const [forecastHidden, setForecastHidden] = useState(false);
    const refValuesIndicators = useNamedRef("valuesIndicators");
    const refValuesIndicatorsPrev = useNamedRef("valuesIndicatorsPrev");
    const refValuesIndicatorsActual = useNamedRef("valuesIndicatorsActual");
    const refValuesIndicatorsForecast = useNamedRef("valuesIndicatorsForecast");

    useEffect(() => {
        // @ts-ignore
        setForecastHidden(localStorage.getItem("isCurrent") !== "true");
    }, [localStorage.getItem("isCurrent")]);
    return (
        <Card style={{ height: "100%" }} ref={refValuesIndicators}>
            <Row justify="space-around">
                <Col
                    data-cy="indicators-last"
                    xs={24}
                    md={props.type !== "licenses" ? 8 : 12}
                    xl={props.type !== "licenses" ? 8 : 12}
                    ref={refValuesIndicatorsPrev}
                >
                    <Statistic
                        loading={props.loading}
                        title={t("shared.last")}
                        value={
                            props.currency
                                ? formatCurrency(
                                      props.currency,
                                      props.indicators.last,
                                      i18n.language,
                                  )
                                : ""
                        }
                        precision={2}
                        suffix={
                            <>
                                <Percentage
                                    actualRef={0}
                                    lastRef={0}
                                    tooltip=""
                                    skip={true}
                                />
                                <br />
                                <ExchangeRate
                                    exchangeRate={
                                        props.indicators.lastExchangeRate
                                    }
                                />
                            </>
                        }
                        className="quantity indicators"
                    />
                </Col>
                {props.type !== "licenses" && (
                    <>
                        <Col data-cy="indicators-actual" xs={24} md={8} xl={8} ref={refValuesIndicatorsActual}>
                            <Statistic
                                loading={props.loading}
                                title={t("shared.actual")}
                                value={
                                    props.currency
                                        ? formatCurrency(
                                              props.currency,
                                              props.indicators.actual,
                                              i18n.language,
                                          )
                                        : ""
                                }
                                precision={2}
                                suffix={
                                    <>
                                        <Percentage
                                            actualRef={props.indicators.actual}
                                            lastRef={props.indicators.last}
                                            tooltip={t(
                                                "dashboard.indicators.tooltips.lastActual",
                                            )}
                                        />
                                        <br />
                                        <ExchangeRate
                                            exchangeRate={
                                                props.indicators.exchangeRate
                                            }
                                        />
                                    </>
                                }
                                className="quantity indicators"
                            />
                        </Col>
                        {!forecastHidden && (
                            <Col
                                data-cy="indicators-forecast"
                                xs={24}
                                md={8}
                                xl={8}
                                ref={refValuesIndicatorsForecast}
                            >
                                <Statistic
                                    loading={props.loading}
                                    title={t("shared.forecast")}
                                    value={
                                        props.currency
                                            ? formatCurrency(
                                                  props.currency,
                                                  props.indicators.forecast,
                                                  i18n.language,
                                              )
                                            : ""
                                    }
                                    precision={2}
                                    suffix={
                                        <Percentage
                                            actualRef={
                                                props.indicators.forecast
                                            }
                                            lastRef={props.indicators.last}
                                            tooltip={t(
                                                "dashboard.indicators.tooltips.lastForecast",
                                            )}
                                        />
                                    }
                                    className="quantity indicators"
                                />
                            </Col>
                        )}
                    </>
                )}
            </Row>
        </Card>
    );
};

export default ValueIndicators;
