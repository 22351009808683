import {
    DeleteOutlined,
    EditOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import {
    Button,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Table,
    Tooltip,
    Typography,
    message,
} from "antd";
import { ColumnType } from "antd/es/table";
import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import "dayjs/locale/en";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import RocketApi from "../../utils/api/rocket-api";
import Loading from "../ui/Loading";

interface DataItem {
    id: string;
    createdAt: string;
    distributorMarkup: number;
    distributorMarkupDiscount: number;
    resellerMarkup: number;
    resellerMarkupDiscount: number;
    initialDate: string;
}

interface EditableCellProps {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text" | "date";
    record: DataItem;
    index: number;
    children: React.ReactNode;
    form: FormInstance;
}

interface EditableTableProps {
    data: DataItem[];
    cloud: any;
    reloadMarkups: () => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    form,
    ...restProps
}) => {
    dayjs.locale("en");
    const { t } = useTranslation();

    const inputNode =
        inputType === "number" ? (
            <InputNumber />
        ) : dataIndex === "initialDate" ? (
            <>
                <DatePicker
                    defaultValue={dayjs(record[dataIndex])}
                    onChange={(date) => {
                        form.setFieldsValue({ [dataIndex]: date });
                    }}
                />
            </>
        ) : (
            <Input />
        );

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const EditableTable = ({ data, cloud, reloadMarkups }: EditableTableProps) => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [dataTable, setData] = useState(data);
    const [editingKey, setEditingKey] = useState("");
    const [loadingRowId, setLoadingRowId] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState<string | null>(null);
    const isEditing = (record: DataItem) => record.id === editingKey;
    const { t } = useTranslation();

    useEffect(() => {
        setData(data);
    }, [data]);

    const edit = (record: DataItem) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const save = async (key: React.Key) => {
        try {
            setLoading(true);
            setLoadingRowId(key as string);
            const row = (await form.validateFields()) as DataItem;

            const newData = [...dataTable];
            const index = newData.findIndex((item) => key === item.id);
            if (index > -1) {
                const item = newData[index];

                const api = new RocketApi();
                await api.request(`${cloud}/markups/${item.id}`, {
                    body: JSON.stringify(row),
                    method: "PUT",
                });

                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setData(newData);
                setEditingKey("");

                messageApi.open({
                    type: "success",
                    content: `${t("dashboard.usages.modals.markups.title")} ${t(
                        "shared.edited",
                    )!}`,
                });
            }
        } catch (error) {
            messageApi.open({
                type: "error",
                content: t(
                    "dashboard.usages.modals.markups.formValidations.messages.errorEdit",
                ),
            });
            console.log("Validate Failed:", error);
        } finally {
            setLoading(false);
            setLoadingRowId(null);
        }
    };

    const deleteRecord = async (record: DataItem) => {
        setLoading(true);
        try {
            setLoadingDelete(record.id);
            const api = new RocketApi();
            await api.request(`${cloud}/markups/${record.id}`, {
                method: "DELETE",
            });

            const newData = dataTable.filter((item) => item.id !== record.id);
            setData(newData);
            messageApi.open({
                type: "success",
                content: t(
                    "dashboard.usages.modals.markups.formValidations.messages.successDelete",
                ),
            });
            reloadMarkups();
        } catch (error) {
            messageApi.open({
                type: "error",
                content: t(
                    "dashboard.usages.modals.markups.formValidations.messages.errorDelete",
                ),
            });
            console.log("Error deleting record:", error);
        } finally {
            setLoading(false);
            setLoadingDelete(null);
        }
    };

    const columns: (
        | ColumnType<DataItem>
        | { editable: boolean; onCell: (record: DataItem) => EditableCellProps }
    )[] = [
        {
            title: t(
                "dashboard.usages.modals.markups.formValidations.inputs.initialDate",
            ),
            dataIndex: "initialDate",
            key: "initialDate",
            sorter: (a: { initialDate: string }, b: { initialDate: any }) =>
                dayjs(b.initialDate).isBefore(a.initialDate) ? -1 : 1,
            sortDirections: ["descend", "ascend"],
            defaultSortOrder: "ascend",
            editable: true,
            fixed: true,
            onCell: (record: any) => ({
                record,
                inputType: "date",
                dataIndex: "initialDate",
                title: t(
                    "dashboard.usages.modals.markups.formValidations.inputs.initialDate",
                ),
                editing: isEditing(record),
            }),
            render(value, record, index) {
                return dayjs(value).format("YYYY-MM-DD");
            },
        },
        {
            title: t(
                "dashboard.usages.modals.markups.formValidations.inputs.distributorMarkup",
            ),
            dataIndex: "distributorMarkup",
            key: "distributorMarkup",
            sorter: (
                a: { distributorMarkup: number },
                b: { distributorMarkup: number },
            ) => a.distributorMarkup - b.distributorMarkup,
            editable: true,
            onCell: (record: any) => ({
                record,
                inputType: "number",
                dataIndex: "distributorMarkup",
                title: t(
                    "dashboard.usages.modals.markups.formValidations.inputs.distributorMarkup",
                ),
                editing: isEditing(record),
            }),
            render(value, record, index) {
                return (
                    <Tooltip title={parseFloat(value)}>
                        {parseFloat(value).toFixed(2)}
                        <InfoCircleOutlined
                            style={{ verticalAlign: "super", width: 15 }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: t(
                "dashboard.usages.modals.markups.formValidations.inputs.distributorMarkupDiscount",
            ),
            dataIndex: "distributorMarkupDiscount",
            key: "distributorMarkupDiscount",
            sorter: (
                a: { distributorMarkupDiscount: number },
                b: { distributorMarkupDiscount: number },
            ) => a.distributorMarkupDiscount - b.distributorMarkupDiscount,
            editable: true,
            onCell: (record: any) => ({
                record,
                inputType: "number",
                dataIndex: "distributorMarkupDiscount",
                title: t(
                    "dashboard.usages.modals.markups.formValidations.inputs.distributorMarkupDiscount",
                ),
                editing: isEditing(record),
            }),
            render(value, record, index) {
                return (
                    <Tooltip title={parseFloat(value)}>
                        {parseFloat(value).toFixed(2)}
                        <InfoCircleOutlined
                            style={{ verticalAlign: "super", width: 15 }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: t(
                "dashboard.usages.modals.markups.formValidations.inputs.resellerMarkup",
            ),
            dataIndex: "resellerMarkup",
            key: "resellerMarkup",
            sorter: (
                a: { resellerMarkup: number },
                b: { resellerMarkup: number },
            ) => a.resellerMarkup - b.resellerMarkup,
            editable: true,
            onCell: (record: any) => ({
                record,
                inputType: "number",
                dataIndex: "resellerMarkup",
                title: t(
                    "dashboard.usages.modals.markups.formValidations.inputs.resellerMarkup",
                ),
                editing: isEditing(record),
            }),
            render(value, record, index) {
                return (
                    <Tooltip title={parseFloat(value)}>
                        {parseFloat(value).toFixed(2)}
                        <InfoCircleOutlined
                            style={{ verticalAlign: "super", width: 15 }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: t(
                "dashboard.usages.modals.markups.formValidations.inputs.resellerMarkupDiscount",
            ),
            dataIndex: "resellerMarkupDiscount",
            key: "resellerMarkupDiscount",
            sorter: (
                a: { resellerMarkupDiscount: number },
                b: { resellerMarkupDiscount: number },
            ) => a.resellerMarkupDiscount - b.resellerMarkupDiscount,
            editable: true,
            onCell: (record: any) => ({
                record,
                inputType: "number",
                dataIndex: "resellerMarkupDiscount",
                title: t(
                    "dashboard.usages.modals.markups.formValidations.inputs.resellerMarkupDiscount",
                ),
                editing: isEditing(record),
            }),
            render(value, record, index) {
                return (
                    <Tooltip title={parseFloat(value)}>
                        {parseFloat(value).toFixed(2)}
                        <InfoCircleOutlined
                            style={{ verticalAlign: "super", width: 15 }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: t(
                "dashboard.usages.modals.markups.formValidations.inputs.actions",
            ),
            dataIndex: "operation",
            className: "text-center",
            render: (_: any, record: DataItem) => {
                const editable = isEditing(record);
                return editable ? (
                    <div style={{ textAlign: "center" }}>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{ marginRight: 20 }}
                        >
                            {t("shared.save")}
                        </Typography.Link>
                        <Popconfirm
                            title={t("formValidations.messages.areUSure")}
                            onConfirm={cancel}
                        >
                            <a>{t("shared.cancel")}</a>
                        </Popconfirm>
                    </div>
                ) : (
                    <>
                        <Button
                            disabled={editingKey !== ""}
                            onClick={() => edit(record)}
                            style={{ marginRight: 10 }}
                            loading={loadingRowId === record.id}
                        >
                            <EditOutlined />
                        </Button>
                        <Popconfirm
                            title={t("formValidations.messages.areUSure")}
                            onConfirm={() => deleteRecord(record)}
                            okText={t("shared.yes")}
                            cancelText={t("shared.no")}
                        >
                            <Button
                                className="btn-danger"
                                loading={loadingDelete === record.id}
                            >
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </>
                );
            },
        },
    ];

    const mergedColumns = columns.map(
        (
            col:
                | ColumnType<DataItem>
                | {
                      dataIndex: string;
                      title: any;
                      render: any;
                      editable: boolean;
                      onCell: (record: DataItem) => EditableCellProps;
                  },
        ) => {
            if (!("editable" in col)) {
                return col;
            }
            return {
                ...col,
                onCell: (record: DataItem) => ({
                    record,
                    inputType:
                        col.dataIndex === "initialDate" ? "date" : "number",
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                }),
                render: col.editable
                    ? (text: any, record: DataItem) => {
                          const editable = isEditing(record);
                          return editable
                              ? text
                              : col.render
                              ? col.render(text, record)
                              : text;
                      }
                    : undefined,
            };
        },
    );

    return (
        <>
            {contextHolder}
            <Form form={form} component={false}>
                <h2 style={{ marginTop: 20 }}>{`${t(
                    "dashboard.usages.btns.markups",
                )} `}</h2>
                <Table
                    components={{
                        body: {
                            cell: (props: any) => (
                                <EditableCell {...props} form={form} />
                            ),
                        },
                    }}
                    bordered
                    dataSource={dataTable.map((item) => ({
                        ...item,
                        key: item.id,
                    }))}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    loading={loading}
                    pagination={{
                        onChange: cancel,
                        defaultPageSize: 5,
                        hideOnSinglePage: true,
                        showSizeChanger: false,
                        locale: {
                            prev_page: t("shared.prevPage"),
                            next_page: t("shared.nextPage"),
                        },
                    }}
                    scroll={{ x: 1000 }}
                    style={{ height: 462 }}
                    locale={{
                        triggerDesc: t("shared.desc"),
                        triggerAsc: t("shared.asc"),
                        cancelSort: t("shared.cancelSort"),
                    }}
                />
            </Form>
        </>
    );
};

export default EditableTable;
