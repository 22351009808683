import i18n from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import TitleSettingsProvider from "./context/TitleContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(
        resourcesToBackend(
            (language: string, namespace: string) =>
                import(`./locales/${language}/${namespace}.json`),
        ),
    )
    .init({
        lng: "en-US",
        fallbackLng: "en-US",
    });

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
        <AuthProvider>
            <TitleSettingsProvider>
                <App />
            </TitleSettingsProvider>
        </AuthProvider>
    </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
