import { Button, Form, Input, Modal, notification } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../../context/AuthContext";

const ChangePassword = ({
    userId,
    userName,
    isVisible,
    onClose,
}: {
    userId: string;
    userName: string;
    isVisible: boolean;
    onClose: () => void;
}) => {
    const { client } = useAuth();
    const [passwordForm] = Form.useForm();
    const { t } = useTranslation();

    useEffect(() => {
        if (isVisible) {
            passwordForm.resetFields();
        }
    }, [userId, isVisible, passwordForm]);

    const handlePasswordChange = () => {
        passwordForm
            .validateFields()
            .then(async (values) => {
                if (values.password !== values.confirmPassword) {
                    notification.error({
                        message: t(
                            "formValidations.messages.errorConfirmPassword",
                        ),
                    });
                    return;
                }

                try {
                    await client.post(`/accounts/change-password`, {
                        userID: userId,
                        password: values.password,
                    });
                    notification.success({
                        message: t(
                            "admin.users.modal.messages.successPasswordChange",
                        ),
                    });
                    onClose();
                    passwordForm.resetFields();
                } catch (error) {
                    console.error(error);
                    notification.error({
                        message: t(
                            "admin.users.modal.messages.errorPasswordChange",
                        ),
                    });
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const handlePasswordCancel = () => {
        onClose();
        passwordForm.resetFields();
    };

    return (
        <Modal
            title={`${t("admin.users.modal.changePasswordTitle")} ${userName}`}
            open={isVisible}
            onOk={handlePasswordChange}
            onCancel={handlePasswordCancel}
        >
            <Form form={passwordForm} layout="vertical">
                <Form.Item
                    name="password"
                    label={t("formValidations.inputs.password")}
                    rules={[
                        {
                            required: true,
                            message: t(
                                "formValidations.messages.requiredPassword",
                            ),
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label={t("formValidations.inputs.confirmPassword")}
                    rules={[
                        {
                            required: true,
                            message: t(
                                "formValidations.messages.requiredConfirmPassword",
                            ),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("password") === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        t(
                                            "formValidations.messages.errorConfirmPassword",
                                        ),
                                    ),
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ChangePassword;
