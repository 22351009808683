import { Card, Col, Collapse, Row, Statistic, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useNamedRef from "../../../hooks/useNamedRef";
import formatCurrency from "../../../utils/formatCurrency";
import { Percentage } from "./percentage";

interface Indicator {
    type?: string | null;
    last: number;
    lastExchangeRate: number | null;
    actual: number;
    exchangeRate: number | null;
    forecast: number;
    last_quantity: number;
    actual_quantity: number;
}

interface ExchangeRateProps {
    exchangeRate: null | number;
}

const ExchangeRate: React.FC<ExchangeRateProps> = ({ exchangeRate }) => {
    const [fxRate, setFxRate] = useState<null | number>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (exchangeRate) {
            setFxRate(exchangeRate);
        }
    }, [exchangeRate]);

    return fxRate ? (
        <Tooltip
            title={t("dashboard.indicators.tooltips.exchangeRate", {
                rate: fxRate,
            })}
        >
            {`@${fxRate}`}
        </Tooltip>
    ) : null;
};

interface ValueIndicatorsProps {
    indicators: Indicator[];
    loading: boolean;
    type: string;
    currency: string;
    activePanel: string[] | undefined;
    onPanelChange: (key: string | string[]) => void;
}

const ValueIndicatorsLicenses: React.FC<ValueIndicatorsProps> = (props) => {
    const { t, i18n } = useTranslation();
    const { indicators, loading, currency, activePanel, onPanelChange } = props;
    const refValueIndicators = useNamedRef("valueIndicators");

    const totalIndicators = indicators.filter(
        (indicator) => indicator.type === "Total",
    );
    const otherIndicators = indicators.filter(
        (indicator) => indicator.type && indicator.type !== "Total",
    );

    return (
        <Card style={{ height: "100%" }} ref={refValueIndicators}>
            {totalIndicators.map((indicator, index) => (
                <Row justify="space-around" key={`values-total-${index}`}>
                    <Col xs={24} md={8} xl={8}>
                        <Statistic
                            loading={loading}
                            title={t("shared.last")}
                            value={
                                props.currency
                                    ? formatCurrency(
                                          props.currency,
                                          indicator.last,
                                          i18n.language,
                                      )
                                    : ""
                            }
                            precision={2}
                            suffix={
                                <>
                                    <Percentage
                                        actualRef={0}
                                        lastRef={0}
                                        tooltip=""
                                        skip={true}
                                    />
                                    <br />
                                    <ExchangeRate
                                        exchangeRate={
                                            indicator.lastExchangeRate
                                        }
                                    />
                                </>
                            }
                            className="quantity indicators"
                        />
                    </Col>
                    <Col xs={24} md={8} xl={8}>
                        <Statistic
                            loading={loading}
                            value={
                                props.currency
                                    ? formatCurrency(
                                          props.currency,
                                          indicator.actual,
                                          i18n.language,
                                      )
                                    : ""
                            }
                            precision={2}
                            title={t("shared.actual")}
                            suffix={
                                <>
                                    <Percentage
                                        actualRef={indicator.actual}
                                        lastRef={indicator.last}
                                        tooltip={t(
                                            "dashboard.indicators.tooltips.lastActual",
                                        )}
                                    />
                                    <br />
                                    <ExchangeRate
                                        exchangeRate={indicator.exchangeRate}
                                    />
                                </>
                            }
                            className="quantity indicators"
                        />
                    </Col>
                    <Col xs={24} md={8} xl={8}>
                        <Statistic
                            loading={loading}
                            value={
                                props.currency
                                    ? formatCurrency(
                                          props.currency,
                                          indicator.forecast,
                                          i18n.language,
                                      )
                                    : ""
                            }
                            precision={2}
                            title={t("shared.forecast")}
                            suffix={
                                <Percentage
                                    actualRef={indicator.forecast}
                                    lastRef={indicator.last}
                                    tooltip={t(
                                        "dashboard.indicators.tooltips.lastForecast",
                                    )}
                                />
                            }
                            className="quantity indicators"
                        />
                    </Col>
                </Row>
            ))}

            <Collapse
                activeKey={activePanel}
                onChange={onPanelChange}
                defaultActiveKey={[]}
                ghost
                className="licenses"
            >
                {otherIndicators.map((indicator, index) => (
                    <Collapse.Panel
                        header={indicator.type}
                        key={`${indicator.type}-licenses`}
                    >
                        <Row
                            justify="space-around"
                            key={`values-other-${index}`}
                            className="others"
                        >
                            <Col xs={24} md={8} xl={8}>
                                <Statistic
                                    loading={loading}
                                    value={
                                        props.currency
                                            ? formatCurrency(
                                                  props.currency,
                                                  indicator.last,
                                                  i18n.language,
                                              )
                                            : ""
                                    }
                                    precision={2}
                                    title={t("shared.last")}
                                    suffix={
                                        <>
                                            <Percentage
                                                actualRef={0}
                                                lastRef={0}
                                                tooltip=""
                                                skip={true}
                                            />
                                            <br />
                                            <ExchangeRate
                                                exchangeRate={
                                                    indicator.lastExchangeRate
                                                }
                                            />
                                        </>
                                    }
                                    className="quantity indicators small"
                                />
                            </Col>
                            <Col xs={24} md={8} xl={8}>
                                <Statistic
                                    loading={loading}
                                    value={
                                        props.currency
                                            ? formatCurrency(
                                                  props.currency,
                                                  indicator.actual,
                                                  i18n.language,
                                              )
                                            : ""
                                    }
                                    precision={2}
                                    title={t("shared.actual")}
                                    suffix={
                                        <>
                                            <Percentage
                                                actualRef={indicator.actual}
                                                lastRef={indicator.last}
                                                tooltip={t(
                                                    "dashboard.indicators.tooltips.lastActual",
                                                )}
                                            />
                                            <br />
                                            <ExchangeRate
                                                exchangeRate={
                                                    indicator.exchangeRate
                                                }
                                            />
                                        </>
                                    }
                                    className="quantity indicators small"
                                />
                            </Col>
                            <Col xs={24} md={8} xl={8}>
                                <Statistic
                                    loading={loading}
                                    value={
                                        props.currency
                                            ? formatCurrency(
                                                  props.currency,
                                                  indicator.forecast,
                                                  i18n.language,
                                              )
                                            : ""
                                    }
                                    precision={2}
                                    title={t("shared.forecast")}
                                    suffix={
                                        <Percentage
                                            actualRef={indicator.forecast}
                                            lastRef={indicator.last}
                                            tooltip={t(
                                                "dashboard.indicators.tooltips.lastForecast",
                                            )}
                                        />
                                    }
                                    className="quantity indicators small"
                                />
                            </Col>
                        </Row>
                    </Collapse.Panel>
                ))}
            </Collapse>
        </Card>
    );
};

export default ValueIndicatorsLicenses;
