import { Card, Col, Row, Statistic } from "antd";
import { useTranslation } from "react-i18next";

import { Indicators } from "../../../components/rocket/types";
import useNamedRef from "../../../hooks/useNamedRef";
import { Percentage } from "./percentage";

const QuantityIndicators = (props: {
    view: any;
    indicators: Indicators;
    loading: boolean;
    type: string;
}) => {
    const { t } = useTranslation();
    let view = props.view;
    const refQuantityIndicators = useNamedRef("quantityIndicators");
    const refQuantityIndicatorsPrev = useNamedRef("quantityIndicatorsPrev");
    const refQuantityIndicatorsActual = useNamedRef("quantityIndicatorsActual");
    return (
        <Card style={{ height: "100%" }} ref={refQuantityIndicators}>
            <Row justify="space-around">
                <Col
                    data-cy="last-quantity-indicators"
                    xs={24}
                    xl={12}
                    ref={refQuantityIndicatorsPrev}
                >
                    <Statistic
                        loading={props.loading}
                        title={
                            props.type !== "licenses"
                                ? t("shared.last") +
                                  (props.view
                                      ? " " + t(`shared.${props.view}`)
                                      : "")
                                : t("dashboard.indicators.titles.licenses")
                        }
                        value={props.indicators.last_quantity}
                        className="quantity quantity-indicators"
                    />
                </Col>
                {props.type !== "licenses" && (
                    <Col
                        data-cy="actual-quantity-indicators"
                        xs={24}
                        xl={12}
                        ref={refQuantityIndicatorsActual}
                    >
                        <Statistic
                            loading={props.loading}
                            title={
                                props.view
                                    ? t(
                                          "dashboard.indicators.titles.actualView",
                                          { view: t(`shared.${view}`) },
                                      )
                                    : ""
                            }
                            value={props.indicators.actual_quantity}
                            suffix={
                                <Percentage
                                    actualRef={props.indicators.actual_quantity}
                                    lastRef={props.indicators.last_quantity}
                                    tooltip={t(
                                        "dashboard.indicators.tooltips.lastActual",
                                    )}
                                />
                            }
                            className="quantity quantity-indicators"
                        />
                    </Col>
                )}
            </Row>
        </Card>
    );
};

export default QuantityIndicators;
