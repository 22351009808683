import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <Result
            status="404"
            title="404"
            subTitle={t("errorPages.messages.notFound")}
            extra={
                // volta para a página anterior ao 404
                <Button type="primary" onClick={() => navigate(-1)}>
                    {t("errorPages.btns.back")}
                </Button>
            }
        />
    );
};

export default NotFound;
