import { Button, Card, theme } from "antd";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import StackedBarChart from "../../../components/rocket/StackedBarChart";
import { InitialValues, KeyValuePair } from "../../../components/rocket/types";
import SkeletonChart from "../../../components/ui/SkeletonChart";
import useNamedRef from "../../../hooks/useNamedRef";
import RocketApi from "../../../utils/api/rocket-api";
import download from "../../../utils/download";

const { useToken } = theme;

const ByMonth = () => {
    const { token } = useToken();
    const { t } = useTranslation();
    const formik = useFormikContext<InitialValues>();
    const refByMonth = useNamedRef("byMonth");
    const refDownloadByMonth = useNamedRef("downloadByMonth");

    const [loadingByMonth, setLoadingByMonth] = useState(false);
    const [byMonth, setByMonth] = useState([]);
    const [localFiltersMonth, setLocalFiltersMonth] = useState<KeyValuePair[]>(
        [],
    );

    useEffect(() => {
        let filters: KeyValuePair[] = [];
        for (const key in formik.values.filters) {
            if (key === "start_time") {
                filters.push({
                    key,
                    //@ts-ignore
                    value: dayjs(formik.values.filters[key])
                        .add(-6, "months")
                        .format("YYYY-MM-DD"),
                });
                continue;
            }

            // @ts-ignore
            if (Array.isArray(formik.values.filters[key])) {
                // @ts-ignore
                for (const item of formik.values.filters[key]) {
                    filters.push({ key, value: item });
                }
            } else {
                // @ts-ignore
                filters.push({ key, value: formik.values.filters[key] });
            }
        }
        if (formik.values.settings.provider === "aws") {
            const params = new URLSearchParams(window.location.search);
            for (const [key, value] of params) {
                if (key === "margin") {
                    filters.push({ key, value });
                }
            }
        }
        setLocalFiltersMonth(filters);
    }, [formik.values.filters, formik.values.settings.provider]);

    useEffect(() => {
        const fetchData = async (controller: AbortController) => {
            try {
                setLoadingByMonth(true);
                const api = new RocketApi();
                const response = await api.request(
                    `${formik.values.settings.provider}/by-month`,
                    {
                        body: JSON.stringify({ filters: localFiltersMonth }),
                        controller,
                    },
                );

                let data = await response?.json();

                data = data.map((d: any) => {
                    const invoiceTypesStorage: string =
                        localStorage.getItem(
                            `invoice_type_${formik.values.settings.provider}`,
                        ) || "";

                    if (invoiceTypesStorage !== "") {
                        const invoiceTypes = JSON.parse(invoiceTypesStorage);
                        if (invoiceTypes.length > 0) {
                            const invoice =
                                invoiceTypes.find(
                                    (type: any) => type.value == d.type,
                                ) || {};

                            d.type = invoice.label || "";
                        }
                    }

                    if (d.type === "" || d.type === "regular") {
                        d.type = "General";
                    }

                    return d;
                });
                setByMonth(data);
                setLoadingByMonth(false);
            } catch (err) {
                console.log(err);
            }
        };

        const controller = new AbortController();
        if (localFiltersMonth.length > 0) {
            fetchData(controller);
        }
        return () => controller.abort();
    }, [localFiltersMonth]);

    return (
        <Card
            title={t("dashboard.byMonth.title")}
            ref={refByMonth}
            extra={
                <Button
                    type="primary"
                    ref={refDownloadByMonth}
                    onClick={() =>
                        download(
                            "by-month",
                            localFiltersMonth,
                            formik.values.settings.provider!,
                            null,
                        )
                    }
                >
                    {t("shared.download")}
                </Button>
            }
            styles={{
                header: {
                    color: token.colorPrimary,
                    borderLeft: `8px solid ${token.colorPrimary}`,
                },
            }}
        >
            {loadingByMonth ? (
                <SkeletonChart type="bar" />
            ) : (
                <StackedBarChart
                    // TODO: Try to pass token colour to theme directly, if it does not work pass the "provider" and use custom themes
                    theme={formik.values.settings.provider!}
                    data={byMonth}
                    isStack={true}
                    xField={"label"}
                    yField={"value"}
                    seriesField={"type"}
                />
            )}
        </Card>
    );
};

export default ByMonth;
