import { Alert, Button, Card, Col, Collapse, Row } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
    KeyValuePair,
    awsDefault,
    azureDefault,
    azureDwDefault,
    gcpDefault,
} from "../../components/rocket/types";
import RocketApi from "../../utils/api/rocket-api";
import QuantityIndicators from "./ui/quantityIndicators";
import ValueIndicators from "./ui/valuesIndicators";

const Introduction = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [loadingAzure, setLoadingAzure] = useState(false);
    const [loadingAzureDW, setLoadingAzureDW] = useState(false);
    const [loadingAWS, setLoadingAWS] = useState(false);
    const [loadingGCP, setLoadingGCP] = useState(false);
    const [indicatorsAzure, setIndicatorsAzure] = useState<{} | null>(null);
    const [indicatorsAzureDW, setIndicatorsAzureDW] = useState<{} | null>(null);
    const [indicatorsAWS, setIndicatorsAWS] = useState<{} | null>(null);
    const [indicatorsGCP, setIndicatorsGCP] = useState<{} | null>(null);
    const [indicatorsAzureLicenses, setIndicatorsAzureLicenses] = useState<
        [] | null
    >(null);
    const [defaultViewAzure, setDefaultViewAzure] = useState("");
    const [defaultViewAzureDW, setDefaultViewAzureDW] = useState("");
    const [defaultViewAWS, setDefaultViewAWS] = useState("");
    const [defaultViewGCP, setDefaultViewGCP] = useState("");
    const [currencyAz, setCurrencyAz] = useState("");
    const [currencyAzDw, setCurrencyAzDw] = useState("");
    const [currencyAws, setCurrencyAws] = useState("");
    const [currencyGcp, setCurrencyGcp] = useState("");

    //@ts-ignore
    const user = JSON.parse(localStorage.getItem("USER"));
    const clouds = user ? user.features : [];

    const fetchSettings = async (controller: AbortController) => {
        const fetchPromises = clouds.map(async (cloud: any) => {
            let filters: KeyValuePair[] = [];

            if (cloud === "azure") {
                setIndicatorsAzure({});
                setIndicatorsAzureLicenses([]);
                setLoadingAzure(true);
                for (const key in azureDefault.filters) {
                    // @ts-ignore
                    if (Array.isArray(azureDefault.filters[key])) {
                        // @ts-ignore
                        for (const item of azureDefault.filters[key] || []) {
                            filters.push({ key, value: item });
                        }
                    } else {
                        filters.push({
                            key,
                            // @ts-ignore
                            value: azureDefault.filters[key] || "",
                        });
                    }
                }

                const api = new RocketApi();
                const response = await api.request(`azure/settings`, {
                    body: JSON.stringify({ filters }),
                    controller,
                });

                const data = await response?.json();
                setCurrencyAz(data.currencyCode);
                if (data.defaultView) {
                    const filters = [
                        {
                            key: "start_time",
                            value: dayjs()
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                        },
                        {
                            key: "end_time",
                            value: dayjs().endOf("month").format("YYYY-MM-DD"),
                        },
                        { key: "view", value: data.defaultView || "" },
                        { key: "invoice_type", value: "1" },
                    ];
                    const responseAz = await api.request(
                        `${cloud}/indicators`,
                        {
                            body: JSON.stringify({ filters }),
                            controller,
                        },
                    );
                    const responseLicenses = await api.request(
                        `${cloud}/indicators/licenses`,
                        {
                            body: JSON.stringify({ filters }),
                            controller,
                        },
                    );
                    const dataAzure = await responseAz?.json();
                    const licenses = await responseLicenses?.json();

                    setIndicatorsAzure(dataAzure);
                    setIndicatorsAzureLicenses(licenses);
                    setLoadingAzure(false);
                    setDefaultViewAzure(data.defaultView || "");
                }
            }

            if (cloud === "azuredw") {
                setLoadingAzureDW(true);
                setIndicatorsAzureDW({});
                for (const key in azureDwDefault.filters) {
                    // @ts-ignore
                    if (Array.isArray(azureDwDefault.filters[key])) {
                        // @ts-ignore
                        for (const item of azureDwDefault.filters[key] || []) {
                            filters.push({ key, value: item });
                        }
                    } else {
                        filters.push({
                            key,
                            // @ts-ignore
                            value: azureDwDefault.filters[key] || "",
                        });
                    }
                }

                const api = new RocketApi();
                const response = await api.request(`azuredw/settings`, {
                    body: JSON.stringify({ filters }),
                    controller,
                });
                const data = await response?.json();
                setCurrencyAzDw(data.currencyCode);
                if (data.defaultView) {
                    const filters = [
                        {
                            key: "start_time",
                            value: dayjs()
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                        },
                        {
                            key: "end_time",
                            value: dayjs().endOf("month").format("YYYY-MM-DD"),
                        },
                        { key: "view", value: data.defaultView || "" },
                    ];
                    const response = await api.request(`${cloud}/indicators`, {
                        body: JSON.stringify({ filters }),
                        controller,
                    });

                    const dataAzureDW = await response?.json();

                    setIndicatorsAzureDW(dataAzureDW);
                    setLoadingAzureDW(false);
                    setDefaultViewAzureDW(data.defaultView || "");
                }
            }

            if (cloud === "aws") {
                setIndicatorsAWS({});
                setLoadingAWS(true);
                for (const key in awsDefault.filters) {
                    // @ts-ignore
                    if (Array.isArray(awsDefault.filters[key])) {
                        // @ts-ignore
                        for (const item of awsDefault.filters[key] || []) {
                            filters.push({ key, value: item });
                        }
                    } else {
                        filters.push({
                            key,
                            // @ts-ignore
                            value: awsDefault.filters[key] || "",
                        });
                    }
                }

                const api = new RocketApi();
                const response = await api.request(`aws/settings`, {
                    body: JSON.stringify({ filters }),
                    controller,
                });
                const data = await response?.json();
                setCurrencyAws(data.currencyCode);
                if (data.defaultView) {
                    const filters = [
                        {
                            key: "start_time",
                            value: dayjs()
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                        },
                        {
                            key: "end_time",
                            value: dayjs().endOf("month").format("YYYY-MM-DD"),
                        },
                        { key: "view", value: data.defaultView || "" },
                    ];
                    const response = await api.request(`${cloud}/indicators`, {
                        body: JSON.stringify({ filters }),
                        controller,
                    });

                    const dataAWS = await response?.json();

                    setIndicatorsAWS(dataAWS);
                    setLoadingAWS(false);
                    setDefaultViewAWS(data.defaultView || "");
                } else {
                    setIndicatorsAWS(null);
                }
            }

            if (cloud === "gcp") {
                setIndicatorsGCP({});
                setLoadingGCP(true);
                for (const key in gcpDefault.filters) {
                    // @ts-ignore
                    if (Array.isArray(gcpDefault.filters[key])) {
                        // @ts-ignore
                        for (const item of gcpDefault.filters[key] || []) {
                            filters.push({ key, value: item });
                        }
                    } else {
                        filters.push({
                            key,
                            // @ts-ignore
                            value: gcpDefault.filters[key] || "",
                        });
                    }
                }

                const api = new RocketApi();
                const response = await api.request(`gcp/settings`, {
                    body: JSON.stringify({ filters }),
                    controller,
                });

                const data = await response?.json();
                setCurrencyGcp(data.currencyCode);
                if (data.defaultView) {
                    const filters = [
                        {
                            key: "start_time",
                            value: dayjs()
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                        },
                        {
                            key: "end_time",
                            value: dayjs().endOf("month").format("YYYY-MM-DD"),
                        },
                        { key: "view", value: data.defaultView || "" },
                    ];
                    const response = await api.request(`${cloud}/indicators`, {
                        body: JSON.stringify({ filters }),
                        controller,
                    });

                    const dataGCP = await response?.json();

                    setIndicatorsGCP(dataGCP);
                    setLoadingGCP(false);
                    setDefaultViewGCP(data.defaultView || "");
                }
            }
        });

        await Promise.all(fetchPromises);
    };

    useEffect(() => {
        const controller = new AbortController();
        fetchSettings(controller);
        return () => controller.abort();
    }, []);

    const renderIndicators = (
        title: string,
        color: string,
        indicators: any,
        loading: boolean,
        navigatePath: string,
        defaultView: string,
        currency: string,
    ) => {
        return (
            <Card
                title={title}
                styles={{
                    header: {
                        color,
                        borderLeft: `8px solid ${color}`,
                    },
                }}
                className="custom-card-header"
                style={{ marginBottom: 40 }}
                extra={
                    <Button
                        type="primary"
                        onClick={() => navigate(navigatePath)}
                        style={{ background: color }}
                    >
                        {t("dashboard.indicators.dashboardBtn")}
                    </Button>
                }
            >
                <Row key={title.toLowerCase()} gutter={[24, 24]}>
                    <Col xs={24} md={16} xl={16}>
                        <ValueIndicators
                            loading={loading}
                            indicators={indicators}
                            type=""
                            currency={currency}
                        />
                    </Col>
                    <Col xs={24} md={8} xl={8}>
                        <QuantityIndicators
                            loading={loading}
                            indicators={indicators}
                            view={defaultView}
                            type=""
                        />
                    </Col>
                </Row>
                {title === "Azure" &&
                    indicatorsAzureLicenses &&
                    indicatorsAzureLicenses.length > 0 && (
                        <Collapse
                            defaultActiveKey={[]}
                            ghost
                            className="licenses"
                        >
                            <Collapse.Panel header="Licenses" key="licenses">
                                <Row gutter={[24, 24]}>
                                    {indicatorsAzureLicenses.map(
                                        (licenses: any, index: number) => (
                                            <Col
                                                xs={24}
                                                md={12}
                                                xl={12}
                                                key={`column-${index}`}
                                            >
                                                <Card
                                                    key={`azure-license-${index}`}
                                                    style={{
                                                        background: "#f7f7f7",
                                                    }}
                                                >
                                                    <h2
                                                        style={{
                                                            color: "#1677ff",
                                                            fontSize: 16,
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {licenses.type}
                                                    </h2>
                                                    <Row
                                                        gutter={[24, 24]}
                                                        style={{
                                                            marginBottom: 20,
                                                        }}
                                                    >
                                                        <Col
                                                            xs={24}
                                                            md={24}
                                                            xl={12}
                                                        >
                                                            <ValueIndicators
                                                                loading={
                                                                    loadingAzure
                                                                }
                                                                indicators={
                                                                    licenses
                                                                }
                                                                type="licenses"
                                                                currency={
                                                                    currency
                                                                }
                                                            />
                                                        </Col>
                                                        <Col
                                                            xs={24}
                                                            md={24}
                                                            xl={12}
                                                        >
                                                            <QuantityIndicators
                                                                loading={
                                                                    loadingAzure
                                                                }
                                                                indicators={
                                                                    licenses
                                                                }
                                                                view={
                                                                    defaultViewAzure
                                                                }
                                                                type="licenses"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        ),
                                    )}
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    )}
            </Card>
        );
    };

    return (
        <>
            <Alert
                message={t("dashboard.messages.homeCurrent")}
                type="info"
                showIcon
                style={{ marginBottom: 15 }}
            />
            {indicatorsAzure &&
                renderIndicators(
                    "Azure",
                    "#1677ff",
                    indicatorsAzure,
                    loadingAzure,
                    "/app/azure",
                    defaultViewAzure,
                    currencyAz,
                )}

            {indicatorsAzureDW &&
                renderIndicators(
                    "Azure DW",
                    "#1677ff",
                    indicatorsAzureDW,
                    loadingAzureDW,
                    "/app/azuredw",
                    defaultViewAzureDW,
                    currencyAzDw,
                )}

            {indicatorsAWS &&
                renderIndicators(
                    "AWS",
                    "#ff9900",
                    indicatorsAWS,
                    loadingAWS,
                    "/app/aws",
                    defaultViewAWS,
                    currencyAws,
                )}

            {indicatorsGCP &&
                renderIndicators(
                    "GCP",
                    "#00b96b",
                    indicatorsGCP,
                    loadingGCP,
                    "/app/gcp",
                    defaultViewGCP,
                    currencyGcp,
                )}
        </>
    );
};

export default Introduction;
