import { DeleteFilled, LeftCircleOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Row,
    Space,
    Tag,
    Tooltip,
    notification,
    theme,
} from "antd";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { InitialValues } from "../../../components/rocket/types";

const { useToken } = theme;

const AppliedFilters = () => {
    const formik = useFormikContext<InitialValues>();
    const { token } = useToken();
    const { t } = useTranslation();
    const history = useNavigate();
    const location = useLocation();
    const [firstRender, setFirstRender] = useState(true);

    const appliedFilters = formik.values.appliedFilters;
    const levels = formik.values.settings.frontend.views;
    const filtersPerLevel = formik.values.settings.frontend.filters;

    const clearFilters = () => {
        // TODO: Change formik type
        // @ts-ignore
        const defaultView = formik.values.settings.defaultView;
        const provider = formik.values.settings.provider;
        // TODO: Change formik type
        // @ts-ignore
        const { start_time, end_time, period } = formik.values.filters;

        const keepFilters =
            provider !== "azure/licenses"
                ? { start_time, end_time, view: defaultView }
                : { view: defaultView, period };
        const resetFilters: any = formik.values.filters;

        const ignore = [
            "invoice_type",
            "cost",
            "catalog",
            "billing_cycle_type",
        ];

        for (let key in resetFilters) {
            // HACK: Only reset if it's not one of
            if (!ignore.includes(key)) {
                resetFilters[key] = "";
            }
        }

        formik.setFieldValue("filters", {
            ...resetFilters,
            ...keepFilters,
        });
        formik.setFieldValue("appliedFilters", []);

        history({ pathname: location.pathname, search: "" });
    };

    const backFilters = () => {
        if (appliedFilters.length >= 1) {
            let { view } = appliedFilters[appliedFilters.length - 1];
            const idx = levels.findIndex((level: any) => level === view);

            const filterKey =
                idx > levels.length
                    ? filtersPerLevel[levels.length]
                    : filtersPerLevel[idx];

            if (idx + 1 >= levels.length) {
                return;
            }

            formik.setFieldValue("filters.view", view);
            formik.setFieldValue(`filters.${filterKey}`, "");

            const newFilters = appliedFilters.slice(0, -1);

            formik.setFieldValue("appliedFilters", newFilters);
            updateURL();
        }
    };

    const updateURL = () => {
        const queryString = `?q=filters:${JSON.stringify(
            formik.values.filters,
        )}`;

        history({
            pathname: location.pathname,
            search: queryString,
        });
    };

    const getCorrespondingView = (filter: string) => {
        const index = filtersPerLevel.indexOf(filter);
        return levels[index];
    };

    useEffect(() => {
        // @ts-ignore
        const ignore = [
            "invoice_type",
            "cost",
            "catalog",
            "start_time",
            "end_time",
            "view",
            "billing_cycle_type",
            "period",
            "status",
        ];
        if (formik.values.options) {
            // @ts-ignore
            let appliedFilterInfo = [];
            for (const key in formik.values.filters) {
                if (formik.values.filters.hasOwnProperty(key)) {
                    if (
                        // @ts-ignore
                        formik.values.filters[key] !== "" &&
                        !ignore.includes(key)
                    ) {
                        // @ts-ignore
                        const value = formik.values.filters[key];
                        if (value !== "" && !ignore.includes(key)) {
                            const view = getCorrespondingView(key);
                            const options = formik.values.options[key] || [];

                            // HACK: if user clicked on usage row we already has created it before, so we just need to reuse it
                            const alreadyApplied = appliedFilters.find(
                                (appliedFilter) => {
                                    if (value === appliedFilter.id) {
                                        if (
                                            value !== null &&
                                            appliedFilter.id !== null
                                        ) {
                                            return true;
                                        } else {
                                            notification.error({
                                                message: t(
                                                    "dashboard.appliedFilters.messages.error",
                                                ),
                                            });
                                            clearFilters();
                                        }
                                    }
                                },
                            );
                            if (alreadyApplied) {
                                appliedFilterInfo.push(alreadyApplied);
                            } else {
                                // try to extract value from saved ( /filters/:type > formik.values.options[key] ) and use it,
                                // otherwise set id and value as the same it comes
                                const idx = options.findIndex(
                                    (option: any) =>
                                        option.value.toLowerCase() ===
                                        value.toLowerCase(),
                                );
                                if (idx > -1) {
                                    appliedFilterInfo.push({
                                        id: options[idx].value,
                                        name: options[idx].label,
                                        view: view,
                                    });
                                } else {
                                    appliedFilterInfo.push({
                                        id: value,
                                        name: value,
                                        view: view,
                                    });
                                }
                            }
                        }
                    }
                }

                formik.setFieldValue("appliedFilters", appliedFilterInfo);
            }
        }
    }, [formik.values.options]);

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
            return;
        }

        const queryString = `?q=filters:${JSON.stringify(
            formik.values.filters,
        )}`;

        history({
            pathname: location.pathname,
            search: queryString,
        });
    }, [appliedFilters, formik.values.filters, history, location.pathname]);

    if (!appliedFilters.length) return <></>;

    return (
        <>
            <Col span={24}>
                <Row justify="space-between" align="middle">
                    <Col>
                        <Space size={[0, 8]} wrap>
                            {appliedFilters.map((filters: any) => {
                                return (
                                    <Tag
                                        style={{
                                            color: "#fff",
                                            background: token.colorPrimary,
                                        }}
                                        id={filters.id}
                                        key={filters.id}
                                    >
                                        {t(`shared.${filters.view}`) +
                                            ": " +
                                            t(`${filters.name}`)}
                                    </Tag>
                                );
                            })}
                        </Space>
                    </Col>
                    <Col>
                        <Tooltip title={t("dashboard.appliedFilters.back")}>
                            <Button size="small" onClick={() => backFilters()}>
                                <LeftCircleOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t("dashboard.appliedFilters.clear")}>
                            <Button size="small" onClick={() => clearFilters()}>
                                <DeleteFilled />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default AppliedFilters;
