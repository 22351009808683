import { Col, Row } from "antd";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Filters from "../../components/rocket/Filters";
import {
    Indicators,
    InitialValues,
    KeyValuePair,
} from "../../components/rocket/types";
import { useTitleSettings } from "../../context/TitleContext";
import RocketApi from "../../utils/api/rocket-api";
import AppliedFilters from "./ui/appliedFilters";
import ErrorBoundary from "./ui/errorBoundary";
import QuantityIndicatorsLicenses from "./ui/quantityIndicatorsLicenses";
import ResourcePieLicenses from "./ui/resourcePieLicenses";
import UsageDetails from "./ui/usageDetails";
import ValueIndicatorsLicenses from "./ui/valuesIndicatorsLicenses";
import ByMonth from "./ui/byMonth";

const DashboardLicenses = () => {
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [indicators, setIndicators] = useState<Indicators[]>([]);

    const [view, setView] = useState("");
    const [localFilters, setLocalFilters] = useState<KeyValuePair[]>([]);
    const [notification, setNotification] = useState(
        localStorage.getItem("isCurrent") === "true",
    );
    const formik = useFormikContext<InitialValues>();
    const { setFilterComponent } = useTitleSettings();
    const { t } = useTranslation();

    useEffect(() => {
        let filters: KeyValuePair[] = [];
        for (const key in formik.values.filters) {
            // @ts-ignore
            if (Array.isArray(formik.values.filters[key])) {
                // @ts-ignore
                for (const item of formik.values.filters[key] || []) {
                    filters.push({ key, value: item });
                }
            } else {
                filters.push({
                    key,
                    // @ts-ignore
                    value: formik.values.filters[key] || "",
                });
            }
        }

        setLocalFilters(filters);
        if (formik.values.filters.hasOwnProperty("view")) {
            // @ts-ignore
            setView(formik.values.filters.view);
        }
    }, [formik.values.filters]);

    useEffect(() => {
        // @ts-ignore
        setNotification(localStorage.getItem("isCurrent") === "true");
    }, [localStorage.getItem("isCurrent")]);

    const fetchIndicators = async (controller: AbortController) => {
        try {
            setLoadingDashboard(true);
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/indicators`,
                {
                    body: JSON.stringify({ filters: localFilters }),
                    controller,
                },
            );

            const data = await response?.json();
            setIndicators(data);
            setLoadingDashboard(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        if (localFilters.length > 0) {
            fetchIndicators(controller);
        }
        return () => controller.abort();
    }, [localFilters]);

    useEffect(() => {
        setFilterComponent(<Filters />);
    }, []);

    const [activePanels, setActivePanels] = useState<string[]>([]);

    const handlePanelChange = (key: string | string[]) => {
        if (Array.isArray(key)) {
            setActivePanels(key);
        } else {
            setActivePanels([key]);
        }
    };

    return (
        <ErrorBoundary>
            <>
                <Row gutter={[24, 24]}>
                    <AppliedFilters />

                    <Col xs={24} md={16} xl={16}>
                        <ValueIndicatorsLicenses
                            loading={loadingDashboard}
                            indicators={indicators}
                            type=""
                            currency={formik.values.settings.currencyCode}
                            activePanel={activePanels}
                            onPanelChange={handlePanelChange}
                        />
                    </Col>
                    <Col xs={24} md={8} xl={8}>
                        <QuantityIndicatorsLicenses
                            loading={loadingDashboard}
                            indicators={indicators}
                            view={view}
                            type=""
                            activePanel={activePanels}
                            onPanelChange={handlePanelChange}
                        />
                    </Col>

                    <Col xs={24} xl={24}>
                        <UsageDetails type={"licenses"} />
                    </Col>
                    <Col xs={24} xl={12}>
                        <ByMonth />
                    </Col>
                    <Col xs={24} xl={12}>
                        <ResourcePieLicenses />
                    </Col>
                </Row>
            </>
        </ErrorBoundary>
    );
};

export default DashboardLicenses;
