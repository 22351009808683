import { ConfigProvider } from "antd";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";

import {
    InitialValues,
    KeyValuePair,
    azureDefault,
    defaultInitialValues,
} from "../components/rocket/types";
import Loading from "../components/ui/Loading";
import Error from "../components/ui/errorTpl";
import { useTitleSettings } from "../context/TitleContext";
import RocketApi from "../utils/api/rocket-api";

const AzureLayout = () => {
    const formik = useFormikContext<InitialValues>();
    const { setFilterEnabled, setLastUpdated, setTitle, reset } =
        useTitleSettings();
    const [error, setError] = useState(false);

    const fetchSettings = async (controller: AbortController) => {
        let filters: KeyValuePair[] = [];

        for (const key in azureDefault.filters) {
            // @ts-ignore
            if (Array.isArray(azureDefault.filters[key])) {
                // @ts-ignore
                for (const item of azureDefault.filters[key] || []) {
                    filters.push({ key, value: item });
                }
            } else {
                filters.push({
                    key,
                    // @ts-ignore
                    value: azureDefault.filters[key] || "",
                });
            }
        }

        const api = new RocketApi();
        const response = await api.request(`azure/settings`, {
            body: JSON.stringify({ filters }),
            controller,
        });
        if (response?.status === 200) {
            const data = await response?.json();
            setLastUpdated(data.lastUpdated);
            setTitle(data.resourcesNames);

            formik.setFieldValue("appliedFilters", []); // HACK: Cleaning up some old state

            formik.setFieldValue("filters.view", data.defaultView);

            formik.setFieldValue("filters.cost", data.defaultCost);
            formik.setFieldValue("settings", data);
        } else {
            setError(true);
        }

        // TODO: better way of wait before render component
        // HACK: Wait for state changes
        await new Promise((res) => setTimeout(res, 1000));
    };

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const controller = new AbortController();
        setFilterEnabled(true);

        const qValue = searchParams.get("q");
        if (qValue) {
            const filtersString = qValue.split("filters:")[1];
            const filters = JSON.parse(decodeURIComponent(filtersString));
            formik.setFieldValue("filters", filters);
        } else {
            formik.setValues(azureDefault);
        }

        fetchSettings(controller);

        return () => {
            controller.abort();
            reset();
            formik.setValues(defaultInitialValues);
        };
    }, []);

    if (formik.values.filters.view === null) {
        return error ? <Error /> : <Loading />;
    }

    if (!formik.values.settings.provider)
        return error ? <Error /> : <Loading />;

    // TODO: Here we need to repass the ConfigProvider to change colours
    return (
        <ConfigProvider>
            <Outlet />
        </ConfigProvider>
    );
};

export default AzureLayout;
