import { notification } from "antd";
import { ArgsProps, IconType } from "antd/es/notification/interface";

const severityToNotification = (type: string): IconType => {
    let severity: Record<string, IconType> = {
        debug: "info",
        info: "info",
        warn: "warning",
        error: "error",
        fatal: "error",
        success: "success",
        // HACK: if we recieve something that we don't map we show it as info
        default: "info",
    };
    return severity[type] || severity["default"];
};

interface BackendException {
    status: number;
    code: string;
    message: string;
    err: string;
    severity: "debug" | "info" | "warn" | "error" | "fatal" | "success";
}

const backendToNotification = (backendErr: BackendException): ArgsProps => {
    let config: ArgsProps = {
        message: `${backendErr.code} (${backendErr.status})`,
        description: backendErr.message,
        type: severityToNotification(backendErr.severity),
    };

    return config;
};

export { severityToNotification, backendToNotification };
