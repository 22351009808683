import {
    FC,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import { useTranslation } from "react-i18next";

export type TitleSettingsContextType = {
    title: string;
    lastUpdated: string;
    filterEnabled: boolean;
    filterComponent: React.ReactNode;

    setTitle: (title: string) => void;
    setLastUpdated: (lastUpdated: Date) => void;
    setFilterEnabled: (filterEnabled: boolean) => void;
    setFilterComponent: (filterComponent: React.ReactNode) => void;
    reset: () => void;
};

export const TitleSettingsContext =
    createContext<TitleSettingsContextType | null>(null);

const TitleSettingsProvider: FC<React.PropsWithChildren> = ({ children }) => {
    const { t, i18n } = useTranslation();

    const lastUpdated = `${new Date().toLocaleString(i18n.language)}`;

    const title = "Cloud Management:";

    const [internalLastUpdated, setInternalLastUpdated] = useState(lastUpdated);
    const [internalTitle, setInternalTitle] = useState(title);
    const [internalFilterEnabled, setInternalFilterEnabled] = useState(false);
    const [internalFilterComponent, setinternalFilterComponent] =
        useState<React.ReactNode>(<></>);

    const setTitle = useCallback((value: string) => {
        if (value) {
            setInternalTitle(
                `Cloud Management: ${
                    value.length > 30 ? value.substring(0, 30) + "..." : value
                }`,
            );
        }
    }, []);

    const setLastUpdated = useCallback(
        (value: Date) =>
            setInternalLastUpdated(
                `${new Date(value).toLocaleString(i18n.language)}`,
            ),
        [],
    );

    const setFilterEnabled = useCallback(
        (value: boolean) => setInternalFilterEnabled(value),
        [],
    );

    const setFilterComponent = useCallback(
        (filterComponent: React.ReactNode) => {
            setinternalFilterComponent(filterComponent);
        },
        [],
    );

    const reset = useCallback(() => {
        setInternalLastUpdated(lastUpdated);
        setInternalTitle(title);
        setInternalFilterEnabled(false);
        setinternalFilterComponent(<></>);
    }, []);

    return (
        <TitleSettingsContext.Provider
            value={{
                filterComponent: internalFilterComponent,
                title: internalTitle,
                filterEnabled: internalFilterEnabled,
                lastUpdated: internalLastUpdated,
                setFilterEnabled,
                setLastUpdated,
                setTitle,
                setFilterComponent,
                reset,
            }}
        >
            {children}
        </TitleSettingsContext.Provider>
    );
};

export const useTitleSettings = () => {
    return useContext(TitleSettingsContext)!;
};

export default TitleSettingsProvider;
