import { GoogleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Row, notification } from "antd";
import { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";

export const LoginContent = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { login } = useAuth();
    const { t, i18n } = useTranslation();

    const [api, contextHolder] = notification.useNotification();

    const onFinish = async (values: any) => {
        setIsSubmitting(true);

        try {
            await login({
                Username: values.username,
                Password: values.password,
            });
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response!.data) {
                    // @ts-ignore
                    api.warning({
                        message: error.response!.data.message,
                    });
                }
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleLanguageChange = (language: string | undefined) => {
        if (language === undefined) {
            return;
        }

        i18n.changeLanguage(language);

        const url = new URL(window.location.href);
        url.searchParams.set("lang", language);

        window.history.pushState({ path: url.href }, "", url.href);
    };

    return (
        <>
            <Row justify={"end"}>
                <Col>
                    <Button
                        className="fi fi-br"
                        onClick={() => handleLanguageChange("pt-BR")}
                    ></Button>
                </Col>
                <Col>
                    <Button
                        className="fi fi-jp"
                        onClick={() => handleLanguageChange("ja-JP")}
                    ></Button>
                </Col>
                <Col>
                    <Button
                        className="fi fi-mx"
                        onClick={() => handleLanguageChange("es-MX")}
                    ></Button>
                </Col>
                <Col>
                    <Button
                        className="fi fi-us"
                        onClick={() => handleLanguageChange("en-US")}
                    ></Button>
                </Col>
            </Row>
            {contextHolder}
            <Form
                name="login"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
            >
                <Form.Item
                    label={t("formValidations.inputs.userName")}
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "formValidations.messages.requiredUsername",
                            ),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t("formValidations.inputs.password")}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "formValidations.messages.requiredPassword",
                            ),
                        },
                    ]}
                >
                    <Input
                        type="password"
                        suffix={
                            <Link to={"/forgot-password"}>
                                {t("login.page.forgotBtn")}
                            </Link>
                        }
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={isSubmitting}
                        type="primary"
                        style={{ width: "100%" }}
                        htmlType="submit"
                    >
                        {t("login.page.loginBtn")}
                    </Button>
                </Form.Item>

                <Divider>{t("shared.or")}</Divider>

                <Form.Item>
                    <Button
                        disabled
                        style={{ width: "100%" }}
                        type="default"
                        icon={<GoogleOutlined />}
                    >
                        {t("login.page.registerGoogle")}
                    </Button>
                </Form.Item>

                {/* <Form.Item>
                    <Button
                        disabled
                        style={{ width: "100%" }}
                        type="default"
                        icon={<FacebookFilled />}
                    >
                        {t("login.page.registerFacebook")}
                    </Button>
                </Form.Item> */}
            </Form>
        </>
    );
};
