const formatCurrency = (currency: string, value: number, lang: string) => {
    const options = {
        style: "currency",
        currency: currency,
        currencyDisplay: "narrowSymbol",
    };
    const numberFormat = new Intl.NumberFormat(lang, options);

    return numberFormat.format(value);
};

export default formatCurrency;
