import { Button, Card, Divider, Form, Input, notification } from "antd";
import { AxiosError } from "axios";
import { useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import { Link, useMatch, useNavigate, useParams } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { severityToNotification } from "../../utils/mapper";

const RecoverCodeContent = () => {
    let { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { client } = useAuth();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const onFinish = async (values: any) => {
        try {
            setIsLoading(true);
            const response = await client.post(
                `/auth/reset-password/${id}`,
                values,
            );
            if (response.status === 200) {
                notification.success({
                    message: t("forgotPassword.messages.success"),
                });

                await new Promise((resolve) => setTimeout(resolve, 3000));
                navigate("/login", { replace: true });
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                // @ts-ignore
                notification[
                    severityToNotification(error.response!.data.severity)
                ]({
                    message: error.response!.data.message,
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Form
            name="forgot-password-2"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                name="password"
                label={t("formValidations.inputs.password")}
                rules={[
                    {
                        required: true,
                        message: t("formValidations.messages.requiredPassword"),
                    },
                ]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="confirm"
                label={t("formValidations.inputs.confirmPassword")}
                dependencies={["password"]}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: t(
                            "formValidations.messages.requiredConfirmPassword",
                        ),
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error(
                                    t(
                                        "formValidations.messages.errorConfirmPassword",
                                    ),
                                ),
                            );
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    style={{ width: "100%" }}
                    htmlType="submit"
                >
                    {t("forgotPassword.btns.reset")}
                </Button>
            </Form.Item>
        </Form>
    );
};

const SendEmailContent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { client } = useAuth();
    const { t } = useTranslation();
    const onFinish = async (values: any) => {
        try {
            setIsLoading(true);
            const response = await client.post("/auth/reset-password", values);
            if (response.status === 200) {
                notification.success({
                    message: t("forgotPassword.messages.sendMail"),
                });
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                // @ts-ignore
                notification[
                    severityToNotification(error.response!.data.severity)
                ]({
                    message: error.response!.data.message,
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Form
            name="forgot-password-1"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                label="E-mail"
                name="email"
                // rules={[{ required: true, message: "Please input your username!" }]}
            >
                <Input />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    style={{ width: "100%" }}
                    htmlType="submit"
                >
                    {t("forgotPassword.btns.sendMail")}
                </Button>
            </Form.Item>

            <Divider>{t("shared.or")}</Divider>

            <Form.Item>
                <Link to="/login">
                    <Button type="default" style={{ width: "100%" }}>
                        {t("forgotPassword.btns.back")}
                    </Button>
                </Link>
            </Form.Item>
        </Form>
    );
};

const contentList: Record<string, React.ReactNode> = {
    SendEmail: <SendEmailContent />,
    RecoverCode: <RecoverCodeContent />,
};

const ForgotPassword = () => {
    let match = useMatch("/forgot-password/:id");
    const { t } = useTranslation();

    const [activeTabKey, setActiveTabKey] = useState("SendEmail");

    useEffect(() => {
        if (match) {
            setActiveTabKey("RecoverCode");
        }
    }, [match]);

    return (
        <>
            <Card
                title={t("forgotPassword.title")}
                style={{ width: 450, minHeight: 200 }}
            >
                {contentList[activeTabKey]}
            </Card>
        </>
    );
};

export default ForgotPassword;
