import { useFormikContext } from "formik";
import Highcharts, { Options, SeriesPieOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsDrilldown from "highcharts/modules/drilldown";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import formatCurrency from "../../utils/formatCurrency";
import SkeletonChart from "../ui/SkeletonChart";
import { InitialValues } from "./types";

highchartsAccessibility(Highcharts);

interface DataItem {
    name: string;
    children: DataItem[];
    value: number;
}

interface PieDrillDownProps {
    data?: DataItem[];
    loading?: boolean;
    height?: any;
    theme?: any;
    type?: string;
}

const PieDrillDown = ({ type, theme, data, loading }: PieDrillDownProps) => {
    const chartRef = useRef<HighchartsReact.Props["chart"]>(null);
    const { t, i18n } = useTranslation();
    const formik = useFormikContext<InitialValues>();

    let color = "";
    switch (theme) {
        case "azure":
            color = "#4096ff";
            break;
        case "azuredw":
            color = "#4096ff";
            break;
        case "gcp":
            color = "#00B96B";
            break;
        case "aws":
            color = "#FF9900";
            break;
        case "ibm":
            color = "#001141";
            break;

        default:
            break;
    }

    const chartOptions: Options = {
        colors: [
            color,
            "#DB6BCF",
            "#2498D1",
            "#BBBDE6",
            "#4045B2",
            "#21A97A",
            "#FF745A",
            "#007E99",
            "#FFA8A8",
            "#2391FF",
            "#FFC328",
            "#A0DC2C",
            "#946DFF",
            "#626681",
            "#EB4185",
            "#CD8150",
            "#36BCCB",
            "#327039",
            "#803488",
            "#83BC99",
        ],
        chart: {
            type: "pie",
            height: 340,
        },
        title: {
            text: "",
            align: "left",
        },
        plotOptions: {
            pie: {
                shadow: false,
                center: ["50%", "50%"],
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            },
        },
        tooltip: {
            formatter: function (
                this: Highcharts.TooltipFormatterContextObject,
            ) {
                const value = formatCurrency(
                    formik.values.settings.currencyCode,
                    this.y!,
                    i18n.language,
                );
                return `<b>${this.point?.name}</b>: ${value}`;
            },
        },
        legend: {
            align: "right",
            verticalAlign: "top",
            layout: "vertical",
            navigation: {
                activeColor: "#3E576F",
                animation: true,
                arrowSize: 12,
                inactiveColor: "#CCC",
                style: {
                    fontWeight: "bold",
                    color: "#333",
                },
            },
            labelFormatter: function (this: any) {
                return this.name.length > 15
                    ? this.name.substring(0, 15) + "..."
                    : this.name;
            },
            x: 0,
            y: 0,
        },
        series: [
            {
                type: "pie",
                name: type,
                colorByPoint: true,
                data: data
                    ?.sort((a: any, b: any) => b.value - a.value)
                    .map((item: DataItem) => ({
                        name: item.name,
                        y: item.value,
                        drilldown: item.name,
                    })),
            },
        ],
        lang: {
            noData: t("shared.noData"),
        },
        drilldown: {
            series: data
                ?.sort((a: any, b: any) => b.value - a.value)
                .map((item: DataItem) => ({
                    type: "pie",
                    name: item.name,
                    id: item.name,
                    data: item.children
                        .sort((a: any, b: any) => b.value - a.value)
                        .map((child: DataItem) => [child.name, child.value]),
                })),
        },
    };

    HighchartsDrilldown(Highcharts);
    HighchartsNoData(Highcharts);
    if (chartRef.current && chartRef.current.chart) {
        chartRef.current.chart.update(chartOptions, true);
    }

    return (
        <div>
            {loading ? (
                <SkeletonChart />
            ) : (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    ref={chartRef}
                />
            )}
        </div>
    );
};

export default PieDrillDown;
