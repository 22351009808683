import { Button, Form, Input, notification } from "antd";
import { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { severityToNotification } from "../../utils/mapper";

export const RegisterContent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { client } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onFinish = async (values: any) => {
        try {
            setIsLoading(true);
            const response = await client.post(`/auth/register`, values);
            if (response.status === 200) {
                notification.success({
                    message: t("login.messages.registerSuccess"),
                });

                await new Promise((resolve) => setTimeout(resolve, 3000));
                navigate("/login", { replace: true });
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                // @ts-ignore
                notification[
                    severityToNotification(error.response!.data.severity)
                ]({
                    message: error.response!.data.message,
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Form
            name="register"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                name="username"
                label={t("formValidations.inputs.userName")}
                rules={[
                    {
                        required: true,
                        message: t("formValidations.messages.requiredUsername"),
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                    {
                        type: "email",
                        message: t("formValidations.messages.validationEmail"),
                    },
                    {
                        required: true,
                        message: t("formValidations.messages.requiredEmail"),
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="password"
                label={t("formValidations.inputs.password")}
                rules={[
                    {
                        required: true,
                        message: t("formValidations.messages.requiredPassword"),
                    },
                ]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="confirm"
                label={t("formValidations.inputs.confirmPassword")}
                dependencies={["password"]}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: t(
                            "formValidations.messages.requiredConfirmPassword",
                        ),
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error(
                                    t(
                                        "formValidations.messages.errorConfirmPassword",
                                    ),
                                ),
                            );
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                >
                    {t("login.page.registerBtn")}
                </Button>
            </Form.Item>
        </Form>
    );
};
