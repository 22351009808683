import { useSyncExternalStore } from "react";

const useLocalStorageValue = (key: string, interval = 10) => {
    const subscribe = (callback: () => void) => {
        let enabled = true;
        const fn = () =>
            setTimeout(() => {
                callback();
                if (enabled) fn();
            }, interval);
        fn();
        return () => {
            enabled = false;
        };
    };

    const getSnapShot = () => {
        return localStorage.getItem(key);
    };

    return useSyncExternalStore(subscribe, getSnapShot, getSnapShot);
};

export default useLocalStorageValue;
