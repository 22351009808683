import { FC, PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";

import Error from "../ui/forbiddenTpl";

const AuthZ: FC<PropsWithChildren<{ features?: string[] }>> = (props) => {
    const { user, isAuthenticated } = useAuth();
    const location = useLocation();

    if (!isAuthenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // component don't have any features specified render it
    if (!props.features || !props.features.length) {
        return <>{props.children}</>;
    }

    // if user features matches at least one of the specified features render it
    if (props.features.some((f) => user?.features.includes(f))) {
        return <>{props.children}</>;
    }

    // don't have the required features
    return <Error/>;
};

export default AuthZ;
