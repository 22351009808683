import { Card, Col, Collapse, Row, Statistic } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import useNamedRef from "../../../hooks/useNamedRef";
import { Percentage } from "./percentage";

interface Indicator {
    type?: string | null;
    last: number;
    lastExchangeRate: number | null;
    actual: number;
    exchangeRate: number | null;
    forecast: number;
    last_quantity: number;
    actual_quantity: number;
}

interface QuantityIndicatorsProps {
    view: any;
    indicators: Indicator[];
    loading: boolean;
    type: string;
    activePanel: string[] | undefined;
    onPanelChange: (key: string | string[]) => void;
}

const QuantityIndicatorsLicenses: React.FC<QuantityIndicatorsProps> = (
    props,
) => {
    const { t } = useTranslation();
    const { indicators, loading, activePanel, onPanelChange } = props;
    const refQuantityIndicators = useNamedRef("quantityIndicators");

    const totalIndicators = indicators.filter((ind) => ind.type === "Total");
    const otherIndicators = indicators.filter(
        (indicator) => indicator.type && indicator.type !== "Total",
    );

    return (
        <Card style={{ height: "100%" }} ref={refQuantityIndicators}>
            {totalIndicators.map((indicator, index) => (
                <Row justify="space-around" key={`total-row-${index}`}>
                    <Col xs={24} xl={12}>
                        <Statistic
                            loading={loading}
                            title={`${t("shared.last")} ${t(
                                `shared.${props.view}`,
                            )}`}
                            value={indicator.last_quantity}
                            className="quantity quantity-indicators"
                        />
                    </Col>
                    <Col xs={24} xl={12}>
                        <Statistic
                            loading={loading}
                            title={`${t("shared.actual")} ${t(
                                `shared.${props.view}`,
                            )}`}
                            value={indicator.actual_quantity}
                            suffix={
                                <Percentage
                                    actualRef={indicator.actual_quantity}
                                    lastRef={indicator.last_quantity}
                                    tooltip={t(
                                        "dashboard.indicators.tooltips.lastActual",
                                    )}
                                />
                            }
                            className="quantity quantity-indicators"
                        />
                    </Col>
                </Row>
            ))}

            <Collapse
                activeKey={activePanel}
                onChange={onPanelChange}
                defaultActiveKey={[]}
                ghost
                className="licenses"
            >
                {otherIndicators.map((indicator, index) => (
                    <Collapse.Panel
                        header={indicator.type}
                        key={`${indicator.type}-licenses`}
                    >
                        <Row justify="space-around" key={`other-row-${index}`}>
                            <Col xs={24} xl={12}>
                                <Statistic
                                    loading={loading}
                                    title={`${t("shared.last")} ${t(
                                        `shared.${props.view}`,
                                    )}`}
                                    value={indicator.last_quantity}
                                    className="quantity quantity-indicators small"
                                />
                            </Col>
                            <Col xs={24} xl={12}>
                                <Statistic
                                    loading={loading}
                                    title={`${t("shared.actual")} ${t(
                                        `shared.${props.view}`,
                                    )}`}
                                    value={indicator.actual_quantity}
                                    suffix={
                                        <Percentage
                                            actualRef={
                                                indicator.actual_quantity
                                            }
                                            lastRef={indicator.last_quantity}
                                            tooltip={t(
                                                "dashboard.indicators.tooltips.lastActual",
                                            )}
                                        />
                                    }
                                    className="quantity quantity-indicators small"
                                />
                            </Col>
                        </Row>
                    </Collapse.Panel>
                ))}
            </Collapse>
        </Card>
    );
};

export default QuantityIndicatorsLicenses;
