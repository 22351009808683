import { ConfigProvider } from "antd";
import { Outlet } from "react-router-dom";

const IbmLayout = () => {
    // TODO: Here we need to repass the ConfigProvider to change colours
    return (
        <ConfigProvider>
            <Outlet />
        </ConfigProvider>
    );
};

export default IbmLayout;
