import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Error = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <Result
            status="500"
            title="500"
            subTitle={t("errorPages.messages.500")}
            extra={
                <Button
                    type="primary"
                    onClick={() => (window.location.search = "")}
                >
                    {t("errorPages.btns.reload")}
                </Button>
            }
        />
    );
};

export default Error;
