import { Card } from "antd";
import { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import {
    Navigate,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { LoginContent } from "./Login";
import { LoginWithToken } from "./LoginWIthToken";
import { RegisterContent } from "./Register";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const tabList = [
    {
        key: "Login",
        tab: (
            <Translation>
                {(t: (arg0: string) => any) => (
                    <>{t("login.page.loginTitle")}</>
                )}
            </Translation>
        ),
    },
    {
        key: "Register",
        tab: (
            <Translation>
                {(t: (arg0: string) => any) => (
                    <>{t("login.page.registerTitle")}</>
                )}
            </Translation>
        ),
    },
];

const contentList: Record<string, React.ReactNode> = {
    Login: <LoginContent />,
    Register: <RegisterContent />,
};

const LoginOrSignup = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { isAuthenticated } = useAuth();

    const [searchParams] = useSearchParams();
    const [activeTabKey, setActiveTabKey] = useState("Login");

    const hasTokenParams =
        searchParams.has("token") && searchParams.has("refreshtoken");

    useEffect(() => {
        if (location.pathname.endsWith("/register")) {
            setActiveTabKey("Register");
        } else if (location.pathname.endsWith("/login")) {
            setActiveTabKey("Login");
        }
    }, [location, location.pathname]);

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
        if (key === "Register") {
            navigate("/register");
        } else if (key === "Login") {
            navigate("/login");
        }
    };

    if (hasTokenParams) {
        return <LoginWithToken />;
    }

    // HACK: user is already authenticated so we redirect to dashboard
    if (isAuthenticated && location.pathname === "/login") {
        return <Navigate to="/app" />;
    }

    return (
        <>
            <Card
                style={{ width: 450, minHeight: 570 }}
                tabList={tabList}
                activeTabKey={activeTabKey}
                onTabChange={onTabChange}
                styles={{
                    header: {
                        textAlign: "center",
                        background: "#fafafa",
                    },
                }}
                title={
                    <img
                        src={`${process.env.PUBLIC_URL}/logo-synnex.png`}
                        alt="Logo TD SYNNEX"
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            height: "auto",
                            padding: "20px",
                        }}
                    />
                }
            >
                {contentList[activeTabKey]}
            </Card>
        </>
    );
};

export default LoginOrSignup;
