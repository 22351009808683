import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { Card, Empty, Table, Tooltip, theme } from "antd";
import { ColumnType, ColumnsType } from "antd/es/table";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import RocketApi from "../../utils/api/rocket-api";
import formatCurrency from "../../utils/formatCurrency";
import { InitialValues, KeyValuePair } from "./types";

const { useToken } = theme;

interface DataItem {
    id: string;
    service: string;
    period: string;
    payment: string;
    term: string;
    estimate_saving_percentage: number;
    estimate_saving: number;
    total_recommendation_count: number;
    details?: ExpandedDataItem[];
    name: string;
}

interface ExpandedDataItem {
    id: string;
    name: string;
    instance: string;
    region: string;
    estimate_saving: number;
    estimate_saving_percentage: number;
}

const RecommendationsTable = () => {
    const formik = useFormikContext<InitialValues>();
    const { t, i18n } = useTranslation();
    const { token } = useToken();

    const [recommendations, setRecommendations] = useState<DataItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [localFilters, setLocalFilters] = useState<KeyValuePair[]>([]);

    useEffect(() => {
        let filters: KeyValuePair[] = [];
        for (const key in formik.values.filters) {
            // @ts-ignore
            if (Array.isArray(formik.values.filters[key])) {
                // @ts-ignore
                for (const item of formik.values.filters[key]) {
                    filters.push({ key, value: item });
                }
            } else {
                // @ts-ignore
                filters.push({ key, value: formik.values.filters[key] });
            }
        }

        setLocalFilters(filters);
    }, [formik.values.filters]);

    const fetchData = async (controller: AbortController): Promise<void> => {
        try {
            setLoading(true);
            const api = new RocketApi();
            const response = await api.request(
                `${formik.values.settings.provider}/recommendations`,
                {
                    body: JSON.stringify({ filters: localFilters }),
                    controller,
                },
            );
            const data = await response?.json();
            const modifiedData = data.map(
                (item: { details: string | any[] }) => {
                    if (item.details && item.details.length > 0) {
                        const { details, ...rest } = item;
                        return { ...rest, children: item.details };
                    }
                    return item;
                },
            );
            setRecommendations(data);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        if (localFilters.length > 0) {
            fetchData(controller);
        }
        return () => controller.abort();
    }, [localFilters]);

    const expandedRowRender = (record: DataItem) => {
        const nestedColumns: ColumnsType<ExpandedDataItem> = [
            { title: "ID", dataIndex: "id", key: "id" },
            {
                title: t("dashboard.usages.tableTitles.name"),
                dataIndex: "name",
                key: "name",
            },
            {
                title: t("dashboard.recommendations.tableTitles.instance"),
                dataIndex: "instance",
                key: "instance",
            },
            {
                title: t("dashboard.recommendations.tableTitles.region"),
                dataIndex: "region",
                key: "region",
            },
            {
                title: t(
                    "dashboard.recommendations.tableTitles.estimate_saving",
                ),
                dataIndex: "estimate_saving",
                key: "estimate_saving",
                render: (value, row, index) => {
                    return (
                        <span>
                            {value.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 2,
                            })}
                        </span>
                    );
                },
                sorter: (a, b) => a.estimate_saving - b.estimate_saving,
            },
            {
                title: (
                    <Tooltip
                        title={t(
                            "dashboard.recommendations.tableTitles.estimate_saving_percentage",
                        )}
                    >
                        <span>
                            %{" "}
                            {t(
                                "dashboard.recommendations.tableTitles.estimate_saving",
                            )}{" "}
                        </span>
                    </Tooltip>
                ),
                dataIndex: "estimate_saving_percentage",
                key: "estimate_saving_percentage",
                render: (value, row, index) => {
                    return <span>{value.toFixed(2)}%</span>;
                },
                sorter: (a, b) =>
                    a.estimate_saving_percentage - b.estimate_saving_percentage,
            },
        ];

        return (
            <Table<ExpandedDataItem>
                //@ts-ignore
                columns={nestedColumns}
                pagination={false}
                rowKey={(row) =>
                    `${row.id}-${row.name}-${row.instance}-${row.region}`
                }
                dataSource={record.details}
            />
        );
    };

    const columns: ColumnType<DataItem>[] = [
        {
            title: t("shared.service"),
            dataIndex: "service",
            key: "service",
            sorter: (a, b) => a.service.localeCompare(b.service),
        },
        {
            title: t("shared.period"),
            dataIndex: "period",
            key: "period",
            sorter: (a, b) => a.period.localeCompare(b.period),
        },
        {
            title: t("dashboard.recommendations.tableTitles.payment"),
            dataIndex: "payment",
            key: "payment",
            sorter: (a, b) => a.payment.localeCompare(b.payment),
        },
        {
            title: t("dashboard.recommendations.tableTitles.term"),
            dataIndex: "term",
            key: "term",
            sorter: (a, b) => a.term.localeCompare(b.term),
        },
        {
            title: t("dashboard.recommendations.tableTitles.estimate_saving"),
            dataIndex: "estimate_saving",
            key: "estimate_saving",
            render: (value, row, index) => {
                return (
                    <span>
                        {formatCurrency(
                            formik.values.settings.currencyCode,
                            value,
                            i18n.language,
                        )}
                    </span>
                );
            },
            sorter: (a, b) => a.estimate_saving - b.estimate_saving,
        },
        {
            title: (
                <Tooltip
                    title={t(
                        "dashboard.recommendations.tableTitles.estimate_saving_percentage",
                    )}
                >
                    <span>
                        %{" "}
                        {t(
                            "dashboard.recommendations.tableTitles.estimate_saving",
                        )}{" "}
                    </span>
                </Tooltip>
            ),
            dataIndex: "estimate_saving_percentage",
            key: "estimate_saving_percentage",
            render: (value, row, index) => {
                return <span>{value.toFixed(2)}%</span>;
            },
            sorter: (a, b) =>
                a.estimate_saving_percentage - b.estimate_saving_percentage,
        },
        {
            title: t(
                "dashboard.recommendations.tableTitles.total_recommendation_count",
            ),
            dataIndex: "total_recommendation_count",
            key: "total_recommendation_count",

            sorter: (a, b) =>
                a.total_recommendation_count - b.total_recommendation_count,
        },
    ];

    return (
        <Card
            title={t("dashboard.recommendations.title")}
            styles={{
                header: {
                    color: token.colorPrimary,
                    borderLeft: `8px solid ${token.colorPrimary}`,
                },
            }}
            className="custom-card-header"
        >
            <Table<DataItem>
                loading={loading}
                bordered={false}
                dataSource={recommendations}
                rowKey={(row) => `${row.id}`}
                columns={columns}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ["0"],
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <MinusCircleTwoTone
                                twoToneColor={token.colorPrimary}
                                onClick={(e) => onExpand(record, e)}
                            />
                        ) : (
                            <PlusCircleTwoTone
                                twoToneColor={token.colorPrimary}
                                onClick={(e) => onExpand(record, e)}
                            />
                        ),
                }}
                locale={{
                    triggerDesc: t("shared.desc"),
                    triggerAsc: t("shared.asc"),
                    cancelSort: t("shared.cancelSort"),
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={t("shared.noData")}
                        />
                    ),
                }}
                pagination={{
                    defaultPageSize: 5,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                    locale: {
                        prev_page: t("shared.prevPage"),
                        next_page: t("shared.nextPage"),
                    },
                }}
                scroll={{ x: 1200 }}
            />
        </Card>
    );
};

export default RecommendationsTable;
