import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Loading from "../../components/ui/Loading";
import { useAuth } from "../../context/AuthContext";

export const LoginWithToken: FC = () => {
    const navigate = useNavigate();

    const { isAuthenticated, loginWithToken } = useAuth();

    const [searchParams] = useSearchParams();

    const hasTokenParams =
        searchParams.has("token") && searchParams.has("refreshtoken");

    useEffect(() => {
        console.log({ hasTokenParams, isAuthenticated });
        if (!isAuthenticated && hasTokenParams) {
            loginWithToken();
        } else if (isAuthenticated) {
            // HACK: Maybe an authenticated user navigate to dashboard
            navigate("/app");
        } else {
            navigate("/login");
        }
    }, [isAuthenticated, hasTokenParams]);

    return <Loading />;
};
