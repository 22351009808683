import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Space,
    Tabs,
    TabsProps,
    notification,
} from "antd";
import { FieldError } from "rc-field-form/es/interface";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../../context/AuthContext";

const resourcesTypeByProvider = {
    gcp: ["distributors", "resellers", "customers"],
    aws: ["distributors", "organizations", "accounts"],
    azure: ["distributors", "resellers", "customers"],
    azuredw: ["distributors", "resellers", "customers"],
    ibm: [],
};

type ResourceTypeProvider = keyof typeof resourcesTypeByProvider;

export const Profiles = () => {
    const form = Form.useFormInstance();
    const { t } = useTranslation();
    const { client } = useAuth();

    const [currentProvider, setCurrentProvider] = useState<
        Record<number, string>
    >({});

    useEffect(() => {
        const formValues = form.getFieldsValue();
        const profiles: Record<number, string> = {};
        const formProfiles = formValues.profiles || [];

        if (formProfiles.length > 0 && formProfiles[0]!) {
            formProfiles.forEach((profile: any, index: number) => {
                if (profile) {
                    profiles[index] = profile.provider;
                }
            });

            setCurrentProvider(profiles);
        }
    }, [form.getFieldValue("profiles")]);

    return (
        <>
            <Form.List name="profiles">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Space
                                key={key}
                                style={{ display: "flex", marginBottom: 8 }}
                                align="baseline"
                            >
                                <Form.Item
                                    {...restField}
                                    name={[name, "provider"]}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t(
                                                "admin.users.modal.formValidations.inputs.provider",
                                            )} ${t(
                                                "formValidations.messages.inputsRequired",
                                            )}`,
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder={t(
                                            "admin.users.modal.formValidations.inputs.provider",
                                        )}
                                        onChange={(
                                            value: ResourceTypeProvider,
                                        ) => {
                                            form.setFieldValue(
                                                [
                                                    "profiles",
                                                    name,
                                                    "resource_type",
                                                ],
                                                resourcesTypeByProvider[
                                                    value
                                                ][0],
                                            );

                                            form.setFieldValue(
                                                ["profiles", name, "provider"],
                                                value,
                                            );

                                            setCurrentProvider((prevState) => ({
                                                ...prevState,
                                                [key]: value,
                                            }));
                                        }}
                                        options={[
                                            {
                                                label: "AWS",
                                                value: "aws",
                                                key: "aws",
                                            },
                                            {
                                                label: "GCP",
                                                value: "gcp",
                                                key: "gcp",
                                            },
                                            {
                                                label: "Azure",
                                                value: "azure",
                                                key: "azure",
                                            },
                                            {
                                                label: "AzureDW",
                                                value: "azuredw",
                                                key: "azuredw",
                                            },
                                        ]}
                                    />
                                </Form.Item>

                                <Form.Item
                                    {...restField}
                                    name={[name, "resource_type"]}
                                >
                                    <Select
                                        placeholder={t(
                                            "admin.users.modal.formValidations.inputs.resources",
                                        )}
                                    >
                                        {(
                                            resourcesTypeByProvider[
                                                currentProvider[
                                                    name
                                                ] as ResourceTypeProvider
                                            ] || []
                                        ).map((item) => (
                                            <Select.Option
                                                value={item}
                                                key={item}
                                            >
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    {...restField}
                                    name={[name, "root"]}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t(
                                                "admin.users.modal.formValidations.inputs.root",
                                            )} ${t(
                                                "formValidations.messages.inputsRequired",
                                            )}`,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t(
                                            "admin.users.modal.formValidations.inputs.root",
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    {...restField}
                                    name={[name, "resources"]}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t(
                                                "admin.users.modal.formValidations.inputs.resources",
                                            )} ${t(
                                                "formValidations.messages.inputsRequired",
                                            )}`,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t(
                                            "admin.users.modal.formValidations.inputs.resources",
                                        )}
                                    />
                                </Form.Item>

                                <Popconfirm
                                    title={t(
                                        "admin.users.messages.confirmDelete",
                                    )}
                                    onConfirm={() => {
                                        const profile = form.getFieldValue([
                                            "profiles",
                                            name,
                                        ]);

                                        const userID = form.getFieldValue("id");

                                        client
                                            .delete(
                                                `/users/${userID}/profiles/${profile.id}`,
                                            )
                                            .then(() => {
                                                notification.success({
                                                    message: t(
                                                        "admin.users.modal.messages.successEdit",
                                                    ),
                                                });
                                                remove(name);
                                            })
                                            .catch((error: Error) => {
                                                notification.error({
                                                    message: t(
                                                        "admin.users.modal.messages.errorEdit",
                                                    ),
                                                });
                                                console.error(error);
                                            });
                                    }}
                                    okText={t("shared.yes")}
                                    cancelText={t("shared.no")}
                                >
                                    <MinusCircleOutlined />
                                </Popconfirm>
                            </Space>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                            >
                                {`${t("shared.add")} ${t(
                                    "admin.users.tableTitles.profiles",
                                )}`}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    );
};

const CreateUser = (props: {
    isModalVisible: boolean;
    setIsModalVisible: Dispatch<SetStateAction<boolean>>;
    refresh: () => void;
}) => {
    const { isModalVisible, setIsModalVisible } = props;
    const [form] = Form.useForm();
    const { client } = useAuth();
    const [activeKey, setActiveKey] = useState("1");
    const [isSubmiting, setIsSubmiting] = useState(false);
    const { t } = useTranslation();

    const handleOk = () => {
        form.validateFields()
            .then(async (values) => {
                if (
                    !values.profiles ||
                    !values.profiles[0].provider ||
                    values.profiles.length === 0
                ) {
                    notification.open({
                        type: "error",
                        message: t("admin.users.modal.messages.profilesError"),
                    });
                    return false;
                }

                // FIX: backend is waiting an array
                if (values["profiles"]) {
                    values.profiles.map(
                        (profile: any) =>
                            (profile.resources = Array.isArray(
                                profile.resources,
                            )
                                ? profile.resources
                                : profile.resources.split(",")),
                    );
                }

                setIsSubmiting(true);

                await client
                    .post(`/users`, values)
                    .then(() => {
                        form.resetFields();
                        setActiveKey("1");
                        notification.success({
                            message: t(
                                "admin.users.modal.messages.successCreate",
                            ),
                        });

                        setIsModalVisible(false);
                        props.refresh();
                        setIsSubmiting(false);
                        form.resetFields();
                    })
                    .catch((error) => {
                        const {
                            response: { data: { message = "" } = {} } = {},
                        } = error;
                        notification.open({
                            type: "error",
                            message: t(
                                "admin.users.modal.messages.errorCreate",
                            ),
                            description: message,
                        });
                        console.error(error);
                        setIsSubmiting(false);
                    });
            })
            .catch((info) => {
                const errors = form.getFieldsError();

                let erroredFields: FieldError[] = [];
                errors.map((field) => {
                    if (field.errors.length > 0) {
                        erroredFields.push(field);
                    }
                });

                if (erroredFields.length > 0) {
                    const label = erroredFields[0].name[0];
                    notification.open({
                        type: "error",
                        message: `Please fill ${label} correctly.`,
                    });
                    return false;
                }
            });
    };

    const handleCancel = () => {
        form.resetFields();
        setActiveKey("1");
        setIsModalVisible(false);
    };

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: t("admin.users.modal.tabs.userInfo"),
            children: (
                <>
                    <Form.Item
                        name="username"
                        label={t("formValidations.inputs.userName")}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    "formValidations.messages.requiredUsername",
                                ),
                            },
                        ]}
                    >
                        <Input placeholder="John Doe" type="text" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                type: "email",
                                message: t(
                                    "formValidations.messages.validationEmail",
                                ),
                            },
                            {
                                required: true,
                                message: t(
                                    "formValidations.messages.requiredEmail",
                                ),
                            },
                        ]}
                    >
                        <Input placeholder="johndoe@company.com" type="email" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label={t("formValidations.inputs.password")}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    "formValidations.messages.requiredPassword",
                                ),
                            },
                        ]}
                    >
                        <Input placeholder="password" type="password" />
                    </Form.Item>
                </>
            ),
        },
        {
            key: "2",
            label: t("admin.users.modal.tabs.roles"),
            children: <>Roles...</>,
            disabled: true,
        },
        {
            key: "3",
            label: t("admin.users.tableTitles.profiles"),
            children: <Profiles />,
        },
    ];

    return (
        <Modal
            destroyOnClose
            title={`${t("shared.create")} ${t("admin.users.title")}`}
            open={isModalVisible}
            confirmLoading={isSubmiting}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form form={form} layout="vertical">
                <Tabs
                    defaultActiveKey="1"
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                    items={items}
                />
            </Form>
        </Modal>
    );
};

export default CreateUser;
