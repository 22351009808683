import { FC } from "react";

const Mock: FC<{ value?: string }> = (props) => {
    if (props.value) {
        return <>{props.value}</>;
    }

    return <>Mock</>;
};

export default Mock;
