import { PercentageOutlined } from "@ant-design/icons";
import {
    Alert,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    message,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import EditableTable from "../../../components/rocket/editableCell";
import RocketApi from "../../../utils/api/rocket-api";

type ModalMarkupsProps = {
    open: boolean;
    onClose: (value: boolean) => void;
    rowData: any;
    editing: boolean;
    markups: any;
    isAdmin: boolean;
    provider: string | null;
};

const ModalMarkups = ({
    open,
    onClose,
    rowData,
    editing,
    markups,
    isAdmin,
    provider,
}: ModalMarkupsProps) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [markupsPerId, setMarkupsPerId] = useState([]);

    useEffect(() => {
        const controller = new AbortController();
        const fetchMarkups = async (id: string) => {
            try {
                if (open) {
                    const api = new RocketApi();
                    const response = await api.request(
                        `${isAdmin ? provider : rowData.cloud}/markups/${id}`,
                        {
                            method: "GET",
                            controller,
                        },
                    );
                    const markupsData = await response?.json();
                    setMarkupsPerId(markupsData);
                }
            } catch (err) {
                console.log(err);
            } finally {
            }
        };

        if (open) {
            fetchMarkups(isAdmin ? rowData.resourceId : rowData.id);
        }

        return () => {
            controller.abort();
        };
    }, [open, rowData.id]);

    const onFinish = (values: any) => {
        const updatedValues = {
            resourceId: rowData.id,
            initialDate: dayjs(values.initialDate).format("YYYY-MM-DD"),
            distributorMarkup: values.distributorMarkup,
            distributorMarkupDiscount: values.distributorMarkupDiscount,
            resellerMarkup: values.resellerMarkup,
            resellerMarkupDiscount: values.resellerMarkupDiscount,
        };
        createMarkup(updatedValues);
    };

    const reloadMarkupsPerId = async () => {
        try {
            const controller = new AbortController();
            const api = new RocketApi();
            const response = await api.request(
                `${isAdmin ? provider : rowData.cloud}/markups/${
                    isAdmin ? rowData.resourceId : rowData.id
                }`,
                {
                    method: "GET",
                    controller,
                },
            );
            const markupsData = await response?.json();
            setMarkupsPerId(markupsData);
        } catch (err) {
            console.log(err);
        }
    };

    const createMarkup = async (values: any): Promise<void> => {
        try {
            setLoading(true);
            const api = new RocketApi();
            await api.request(`${isAdmin ? provider : rowData.cloud}/markups`, {
                body: JSON.stringify(values),
            });
            setLoading(false);
            form.resetFields();

            messageApi.open({
                type: "success",
                content: `${t("dashboard.usages.modals.markups.title")} ${
                    editing ? t("shared.edited") : t("shared.created")!
                }`,
            });
            reloadMarkupsPerId();
        } catch (err) {
            messageApi.open({
                type: "error",
                content: t(
                    "dashboard.usages.modals.markups.formValidations.messages.errorCreate",
                ),
            });
        }
    };

    const handleCancel = () => {
        onClose(false);
        form.resetFields();
    };

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                onFinish(values);
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    return (
        <>
            {contextHolder}
            <Modal
                title={`${t("shared.add")} ${t(
                    "dashboard.usages.modals.markups.title",
                )}`}
                open={open}
                key={`modal_${rowData.id}`}
                okText={editing ? t("shared.edit") : t("shared.create")}
                onCancel={handleCancel}
                onOk={handleOk}
                className="modal-markups"
                footer=""
            >
                {rowData && (
                    <>
                        <Alert
                            message={t(
                                "dashboard.usages.modals.markups.messages.alertCreate",
                            )}
                            type="info"
                            showIcon
                            style={{ marginBottom: 15 }}
                        />
                        <Form
                            form={form}
                            layout="vertical"
                            name="form"
                            className="form-markup"
                        >
                            <Row gutter={[24, 24]}>
                                <Col span={12}>
                                    <Form.Item
                                        label={t(`shared.resource`)}
                                        tooltip={t(`shared.resource`)}
                                    >
                                        <Input
                                            disabled={
                                                isAdmin && !editing
                                                    ? false
                                                    : true
                                            }
                                            value={
                                                isAdmin
                                                    ? rowData.resourceId
                                                    : rowData.name
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="initialDate"
                                        label={t(
                                            "dashboard.usages.modals.markups.formValidations.inputs.initialDate",
                                        )}
                                        tooltip={t(
                                            "dashboard.usages.modals.markups.tooltips.initialDate",
                                        )}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "dashboard.usages.modals.markups.formValidations.messages.initialDateRequired",
                                                ),
                                            },
                                        ]}
                                    >
                                        <DatePicker format="YYYY-MM-DD" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="distributorMarkup"
                                        label={t(
                                            "dashboard.usages.modals.markups.formValidations.inputs.distributorMarkup",
                                        )}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "dashboard.usages.modals.markups.formValidations.messages.distributorMarkupRequired",
                                                ),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            addonBefore={<PercentageOutlined />}
                                            placeholder={`${t(
                                                "formValidations.inputs.value",
                                            )} %`}
                                            type="number"
                                            min={1}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="distributorMarkupDiscount"
                                        label={t(
                                            "dashboard.usages.modals.markups.formValidations.inputs.distributorMarkupDiscount",
                                        )}
                                    >
                                        <InputNumber
                                            addonBefore={<PercentageOutlined />}
                                            placeholder={`${t(
                                                "formValidations.inputs.value",
                                            )} %`}
                                            type="number"
                                            min={1}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="resellerMarkup"
                                        label={t(
                                            "dashboard.usages.modals.markups.formValidations.inputs.resellerMarkup",
                                        )}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "dashboard.usages.modals.markups.formValidations.messages.resellerMarkupRequired",
                                                ),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            addonBefore={<PercentageOutlined />}
                                            placeholder={`${t(
                                                "formValidations.inputs.value",
                                            )} %`}
                                            type="number"
                                            min={1}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="resellerMarkupDiscount"
                                        label={t(
                                            "dashboard.usages.modals.markups.formValidations.inputs.resellerMarkupDiscount",
                                        )}
                                    >
                                        <InputNumber
                                            addonBefore={<PercentageOutlined />}
                                            placeholder={`${t(
                                                "formValidations.inputs.value",
                                            )} %`}
                                            type="number"
                                            min={1}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="end">
                                <Button
                                    key="back"
                                    onClick={handleCancel}
                                    style={{ marginRight: 10 }}
                                >
                                    {t("shared.cancel")}
                                </Button>
                                <Button
                                    key="submit"
                                    type="primary"
                                    onClick={handleOk}
                                    loading={loading}
                                >
                                    {editing && !isAdmin
                                        ? t("shared.edit")
                                        : t("shared.create")}
                                </Button>
                            </Row>
                        </Form>
                    </>
                )}
                {markupsPerId && markupsPerId.length > 0 && (
                    <EditableTable
                        data={markupsPerId}
                        cloud={rowData.cloud || provider}
                        reloadMarkups={reloadMarkupsPerId}
                    />
                )}
            </Modal>
        </>
    );
};

export default ModalMarkups;
