// REF: https://github.com/auth0/auth0-react/blob/8e1de454fa5f7cd5e9ce9516e6e680b11d3d5697/EXAMPLES.md#4-create-a-useapi-hook-for-accessing-protected-apis-with-an-access-token
// REF: https://github.com/divanov11/refresh-token-interval/blob/2eb7d731017ac3383279ca19be2a213c4be414c3/frontend/src/context/AuthContext.js
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    InternalAxiosRequestConfig,
} from "axios";
import {
    FC,
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

const REFRESH_INTERVAL = 1000 * 60 * 60 * 1; // 1 hour

interface IUser {
    grantedPermissions: string[];
    grantedRoles: string[];
    id: string;
    permissions: string[];
    roles: string[];
    features: string[];
    userId: string;
    buildTime: string;
    shortCommit: string;
    version: string;
}

interface IAuthContext {
    user: IUser;
    isLoading: boolean;
    isAuthenticated: boolean;
    login: any;
    loginWithToken: any;
    logout: any;
    client: AxiosInstance;
    loginProgrammatic: any;
}

const stub = () => {
    throw Error("Did you forgot to use AuthProvider?");
};

const initialState = {
    user: {
        grantedPermissions: [],
        grantedRoles: [],
        id: "",
        permissions: [],
        roles: [],
        features: [],
        userId: "",
        buildTime: "",
        shortCommit: "",
        version: "",
    },
    isLoading: true,
    isAuthenticated: false,
    login: stub,
    loginWithToken: stub,
    logout: stub,
    client: axios,
    loginProgrammatic: stub,
};

export const AuthContext = createContext<IAuthContext>(initialState);

export const useAuth = () => {
    return useContext(AuthContext);
};

export const useApi = (
    url: string,
    options: AxiosRequestConfig | undefined = undefined,
) => {
    const { client } = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [refreshIndex, setRefreshIndex] = useState(0);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const res = await client(url, options);
                setData(res.data);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [refreshIndex, client, url, options]);

    return {
        data,
        loading: isLoading,
        error,
        refresh: () => setRefreshIndex(refreshIndex + 1),
    };
};

interface IToken {
    token: string;
    refreshToken: string;
}

const getAuthTokens = (): IToken | null =>
    localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens")!)
        : null;

export const getUserLocalStorage = (): IUser | null => {
    const userString = localStorage.getItem("USER");
    return userString ? JSON.parse(userString) : null;
};

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    let [authTokens, setAuthTokens] = useState(() => getAuthTokens());
    const [user, setUser] = useState(() => getUserLocalStorage());

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const [client] = useState(() => {
        const client = axios.create({
            withCredentials: true,
            baseURL:
                process.env.REACT_APP_BACKEND_URL ||
                `${window.location.origin}/rocketsvc/api/v4`,
        });

        const onRequest = (
            config: InternalAxiosRequestConfig,
        ): InternalAxiosRequestConfig => {
            let authTokens = getAuthTokens();
            if (authTokens) {
                config.headers!["Authorization"] = `Bearer ${authTokens.token}`;
            }

            return config;
        };

        const onRequestError = async (
            error: AxiosError,
        ): Promise<AxiosError> => {
            return Promise.reject(error);
        };

        const onResponse = (response: AxiosResponse): AxiosResponse => {
            return response;
        };

        const onResponseError = async (error: any) => {
            const originalRequest = error.config;
            if (error.response?.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true;
                await refreshTokenSilently();

                return client(originalRequest);
            }
            return Promise.reject(error);
        };

        client.interceptors.request.use(onRequest, onRequestError);
        client.interceptors.response.use(onResponse, onResponseError);

        return client;
    });

    let login = useCallback(
        async (params: any) => {
            await client.post("/auth/login", params).then(async (response) => {
                if (response.status === 200) {
                    setAuthTokens(response.data);
                    localStorage.setItem(
                        "authTokens",
                        JSON.stringify(response.data),
                    );
                    localStorage.removeItem("alerts");

                    await client.get("/accounts/me").then((res) => {
                        if (res.status === 200) {
                            const user = res.data as IUser;

                            setUser(user);
                            localStorage.setItem("USER", JSON.stringify(user));

                            if (user.features.includes("admin")) {
                                navigate("/app/admin/users");
                            } else if (user.features.length === 1) {
                                navigate(`/app/${user.features[0]}`);
                            } else {
                                navigate("/app");
                            }
                        }
                    });
                }
            });
        },
        [client],
    );

    let loginProgrammatic = useCallback(async (params: any) => {
        const baseURL =
            process.env.REACT_APP_BACKEND_URL ||
            `${window.location.origin}/rocketsvc/api/v4`;

        const options = {
            method: "POST",
            url: `${baseURL}/programmatic/login`,
            headers: {
                Authorization: `Basic ${btoa(params.user + ":" + params.pass)}`,
            },
        };
        await axios
            .request(options)
            .then(async (response) => {
                if (response.status === 200) {
                    setAuthTokens(response.data);
                    localStorage.setItem(
                        "authTokens",
                        JSON.stringify(response.data),
                    );
                    localStorage.removeItem("alerts");

                    await client.get("/accounts/me").then((res) => {
                        if (res.status === 200) {
                            const user = res.data as IUser;

                            setUser(user);
                            localStorage.setItem("USER", JSON.stringify(user));

                            if (user.features.includes("admin")) {
                                navigate("/app/admin/users");
                            } else if (user.features.length === 1) {
                                navigate(`/app/${user.features[0]}`);
                            } else {
                                navigate("/app");
                            }
                        }
                    });
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }, []);

    const logout = useCallback(async () => {
        client
            .get("/auth/logout")
            .catch(() => {})
            .finally(() => {
                setAuthTokens(null);
                setUser(null);
                localStorage.removeItem("USER");
                localStorage.removeItem("authTokens");
                return Promise.resolve();
            });
    }, [client]);

    let refreshTokenSilently = useCallback(async () => {
        try {
            let response = await client.post(`/auth/refresh-token`);
            if (response.status === 200) {
                setAuthTokens(response.data);
                localStorage.setItem(
                    "authTokens",
                    JSON.stringify(response.data),
                );

                response = await client.get("/accounts/me");
                if (response.status === 200) {
                    setUser(response.data);
                    localStorage.setItem("USER", JSON.stringify(response.data));
                }
            }
        } catch (error) {
            await logout();
        } finally {
            if (isLoading) {
                setIsLoading(false);
            }
        }
    }, [authTokens]);

    const loginToken = () => {
        const urlSearchParams = new URLSearchParams(location.search);
        const token = urlSearchParams.get("token");
        const embed = urlSearchParams.get("embed") === "true";
        const margin = urlSearchParams.get("margin");
        const refreshToken = urlSearchParams.get("refreshtoken");

        if (token && refreshToken) {
            setAuthTokens({ token: token, refreshToken: refreshToken });
            localStorage.setItem(
                "authTokens",
                JSON.stringify({ token: token, refreshToken: refreshToken }),
            );

            client
                .get("/accounts/me")
                .then((res) => {
                    if (res.status === 200) {
                        const user = res.data as IUser;

                        setUser(user);
                        localStorage.setItem("USER", JSON.stringify(user));
                        localStorage.setItem(`embed`, embed.toString());

                        let propagateParams = "?";

                        if (margin) {
                            propagateParams += "margin=" + margin;
                        }

                        if (user.features.length >= 1) {
                            navigate(
                                `/app/${user.features[0]}${propagateParams}`,
                            );
                        } else {
                            navigate(`/app${embed ? "?embed=true" : ""}`);
                        }
                    }
                })
                .catch(async (err) => {
                    await logout();
                })
                .finally(() => {
                    if (isLoading) {
                        setIsLoading(false);
                    }
                });
        }
    };

    // Since react 18 components could be recreated
    // REF: https://youtu.be/MXSuOR2yRvQ
    const shouldRefresh = useRef(true);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        const token = urlSearchParams.get("token");

        let interval: NodeJS.Timer;

        if (shouldRefresh.current) {
            if (token) {
                setAuthTokens(null);
                loginToken();
            } else {
                if (isLoading) {
                    refreshTokenSilently();
                }

                interval = setInterval(() => {
                    if (authTokens) {
                        refreshTokenSilently();
                    }
                }, REFRESH_INTERVAL);
            }

            shouldRefresh.current = false;
        }

        return () => clearInterval(interval);
    }, []);

    let contextData = {
        user: user!,
        isLoading,
        isAuthenticated: !!user,
        login,
        loginWithToken: loginToken,
        logout,
        loginProgrammatic,
        client,
    };

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    );
};
