import { MutableRefObject, useRef } from "react";

const namedRefsMap = new Map<string, MutableRefObject<any>>();

const useNamedRef = (name: string, initialValue: any = null) => {
    const newRef = useRef(initialValue);

    if (!namedRefsMap.has(name)) {
        namedRefsMap.set(name, newRef);
    }
    return namedRefsMap.get(name);
};

export default useNamedRef;
